import React from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";

export default function StatisticsComponent() {
  
  return (
    <div style={{ marginTop: "100px" }}>
      <Hidden mdDown>
      <Grid
        container
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          position: "relative",
          color: "#ffffff",
        }}
      >
        <svg width="100%" viewBox="0 0 1600 400">
          <defs>
            <linearGradient id="PSgrad_03" x1="80.279%" x2="0%" y2="0%">
              <stop
                offset="0%"
                stopColor="rgb(251, 179, 27, 0.8)"
                stopOpacity="1"
              />
              <stop
                offset="100%"
                stopColor="rgb(251, 179, 27, 1)"
                stopOpacity="1"
              />
            </linearGradient>
          </defs>
          {/* <!-- */}
          <clipPath id="clip-path3"></clipPath>
          {/* --> */}

          <path
            fillRule="evenodd"
            fill="#59C8E2"
            d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"
             >
                  </path>

          <clipPath id="ctm" fill="none">
            <path d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"></path>
          </clipPath>
        </svg>
        <Grid
          item
                  style={{
          
            position: "absolute",
            top: "40%",
            left: "15%",
            zIndex: 1000,
            //color: "rgb(89, 200, 226)",
            color: "#ffffff",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Lato-Bold",
                fontSize: "48px",
              }}
            >
              5
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Semibold",
                fontSize: "18px",
              }}
            >
              Languages Supported
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            position: "absolute",
            top: "40%",
            left: "35%",
            zIndex: 1000,
            color: "#ffffff",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Lato-Bold",
                fontSize: "48px",
              }}
            >
              165
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Semibold",
                fontSize: "18px",
              }}
            >
              Active users
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            position: "absolute",
            top: "40%",
            left: "55%",
            zIndex: 1000,
            color: "#ffffff",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Lato-Bold",
                fontSize: "48px",
              }}
            >
              9857
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Semibold",
                fontSize: "18px",
              }}
            >
              Minutes Transcribed
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            position: "absolute",
            top: "40%",
            left: "75%",
            zIndex: 1000,
            color: "#ffffff",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Lato-Bold",
                fontSize: "48px",
              }}
            >
              Free
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Semibold",
                fontSize: "18px",
              }}
            >
              Price
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
    </div>
  );
}
