import React from "react";
import { Grid, Hidden,Divider } from "@material-ui/core";
import styles from "./css/PrivacyCss";

export default function PrivacySection() {
  const classes = styles();
  return (
    <div>
      <Grid container justify="center">
      <Grid item xs={10} sm={10} md={10} alignItems='center'>
          <Hidden mdUp>  
      <h3 className={classes.title}>Privacy Policy</h3>
      < Divider/>
      </Hidden>
     <h5 className={classes.sideheadings1
    }> Effective July 4th, 2020</h5>
      <h5 className={classes.description}>
        This privacy policy (“Policy”) informs you of our practices when handling your Personal
        Information through the Services (both as defined below). In this Policy, “scriver.in”,
        “we” or “us” refers to scriver.in, a website registered in India. We are the data controller
        under the applicable privacy laws. Where we have an Scriver for Teams or enterprise
        service agreement in place with an enterprise customer who is asking you to use our
        Services (for example your employer), we obtain and process your Personal Information
        on behalf of and at the instructions of that customer. In that context, such enterprise
        customers are the data controllers and their privacy policies will apply to the processing
        of your Information. We encourage you to read their privacy policies. For the purpose of
        this Policy, “Personal Information” means any information relating to an identified or
        identifiable individual. This includes Personal Information you provide or generate when
        you use: (a) Our Scriver meeting assistant app (the “App”); and (b), https://scriver.in,and
        any other dedicated scriver.in websites (the “Website”) (collectively, the “Services”).
        When you use the Services, you accept and understand that we collect, process, use,
        and store your Personal Information as described in this Policy. If you do not agree with
        this Policy, you must not use any of the Services. If you change your mind in the future
        you must stop using the Services and you may exercise your rights concerning your
        Personal Information as set out in this Policy.</h5>

        <h5 className={classes.descriptionbold}>
        If you do not agree with this Policy, you must not use any of the Services. If you
        change your mind in the future you must stop using the Services and you may
        exercise your rights concerning your Personal Information as set out in this
        Policy.
        </h5>
        <h5 className={classes.sideheadings}>
        1. INFORMATION WE COLLECT
        </h5>
        <h5 className={classes.description}>
        We will collect and use the following Personal Information about you:
        </h5>
        <h5 className={classes.description}>
        Information you provide to us
        </h5>
        <h5 className={classes.sideheadings}>
        ● Registration Information.</h5> 
        <h5 className={classes.description}>When you create an account on our Services, you
        will be asked to provide your name, email, and a password, you may
        voluntarily add a profile picture.
        </h5>
        <h5 className={classes.sideheadings}>
        ● App Information.</h5> 
        <h5 className={classes.description}> When you use the Services, you may provide us with your
        audio recordings (“Audio Recordings”) and any text, images or videos that
        you upload or provide to us in the context of the Services.
        </h5>
        <h5 className={classes.sideheadings}>
        ● Communication Information. </h5> 
        <h5 className={classes.description}> When you contact us, you provide us with your
        phone number, email, and any other information you choose to provide over
        such communication, including information about your query.</h5>
        <h5 className={classes.sideheadings}>
        Information we automatically collect or is generated about you when use the
Services
</h5>
<h5 className={classes.sideheadings}>
● Usage Information:  </h5> 
        <h5 className={classes.description}>  When you use the Services, you generate information
pertaining to your use, including timestamps, such as access, record, share,
edit and delete events, app use information, interactions with our team, and
transaction records.
</h5>

<h5 className={classes.sideheadings}>
● Device Information:  </h5> 
        <h5 className={classes.description}>  We assign a unique user identifier (“UUID”) to each
mobile device that accesses the Services. When you use our Services, you
provide information such as your IP address, UUIDs, device IDs, web beacons
and other device information (such as carrier type, whether you access our
Service from a desktop or mobile platform, device model, brand, web browser
and operating system).
</h5>
<h5 className={classes.sideheadings}>
● Cookies: </h5> 
        <h5 className={classes.description}>  We use Cookies and other similar technologies (“Cookies”) to
enhance your experience when using the Service. For more information about
our Cookies policy, see HOW WE USE COOKIES AND SIMILAR
TECHNOLOGIES below.
</h5>

<h5 className={classes.sideheadings}>
Information received from third parties.

</h5>
<h5 className={classes.sideheadings}>
● Information we receive from third party platforms:  </h5> 
        <h5 className={classes.description}>   When you connect third
party platforms, apps or providers (such as Google Docs) to our Services, or
when you register through a third party account (such as Google), we receive
Personal Information that includes your username, profile picture, email
address, time, location, calendar information, contact information from such
third parties and any information you choose to upload to such third party
platforms (“Platform Information”).

</h5>

<h5 className={classes.sideheadings}>
● Information from platforms our Services relies on: </h5> 
        <h5 className={classes.description}>   We receive transaction
information from our payment processor Stripe.
</h5>
<h5 className={classes.sideheadings}>
● Other third parties. </h5> 
        <h5 className={classes.description}>  We may receive additional information about you, such as
demographic or interest attributes from third parties such as data or marketing
partners and combine it with other information we have about you.

</h5>

<h5 className={classes.description}>  We also collect, and use aggregated data such as statistical or demographic data for
our purposes. Aggregated data may be derived from your Personal Information but is
not Personal Information as this data will not directly or indirectly reveal your identity.
However, if we combine or connect aggregated data with your Personal Information so
that it can directly or indirectly identify you, we will treat the combined data as Personal
Information which will be used in accordance with this Policy.

</h5>
<h5 className={classes.sideheadings}>
2. HOW WE USE YOUR PERSONAL INFORMATION </h5>

<h5 className={classes.sideheadings}>
We use your Personal Information to: </h5>
<h5 className={classes.sideheadings}>
● Set up your account.  </h5> 
        <h5 className={classes.description}>    We use your registration information, device information
and information received from third parties (such as your username, email
address) in order to set up an account for you to use our Services. We do so in
accordance with our contractual and precontractual obligations to you in order
to provide you with an account to use the Services.

</h5>

<h5 className={classes.sideheadings}>
● Provide you with the Services.  </h5> 
        <h5 className={classes.description}>   We use your audio recordings, usage
information and platform information in order to provide you with the Services.
In addition, we use your communication information to facilitate support (e.g.
retrieval of a forgotten password). We do so in accordance with our
contractual obligations to you in order to provide you with the Services.
</h5>
<h5 className={classes.sideheadings}>
● Improve and monitor the Services. </h5> 
        <h5 className={classes.description}> We use information we automatically
collect or generate about you when you use the Services, as well as
non-personal information about your device such as device manufacturer,
model and operating system, and the amount of free space on your device, to
analyze the use of and improve our Services. We train our proprietary artificial
intelligence technology on aggregated, de-identified audio recordings. Only
with your explicit permission will we manually review certain audio recordings
to further refine our model training data.
</h5>
<h5 className={classes.sideheadings}>
● Communicate with you. </h5> 
<h5 className={classes.description}> If you contact us, we will use your contact
information to communicate with you and, if applicable, your usage information
to support your use of the Services.
</h5>

<h5 className={classes.sideheadings}>
● Prevent fraud, defend Scriver.in against legal claims or disputes, enforce
our terms and to comply with our legal obligations. </h5> 
<h5 className={classes.description}>  It is in our legitimate
interest to protect our interests by (1) monitoring the use of the Services to
detect fraud or any other user behavior which prejudices the integrity of our
Services, (2) taking steps to remedy aforementioned fraud and behavior, (3)
defending ourselves against legal claims or disputes, and (4) enforcing our
terms and policies. When doing so, we will process the Personal Information
relevant in such a case, including information you provide us, information we
automatically collect about you, and information which is provided to us by
third parties.

</h5>

<h5 className={classes.sideheadings}>
3. HOW WE USE COOKIES AND SIMILAR TECHNOLOGIES </h5> 

<h5 className={classes.description}> We and our third party partners use Cookies, pixel tags, and similar technologies to
collect information about your browsing activities and to distinguish you from other users
of our Services in order to aid your experience and measure and improve our
advertising effectiveness.</h5> <h5 className={classes.description}>
Cookies are small files of letters and numbers that we store on your browser or on your
device. They contain information that is transferred to your device.</h5>
<h5 className={classes.description}>
We use Cookies to collect information about your browsing activities and to distinguish
you from other users of our Services in order to aid your experience.</h5> <h5 className={classes.description}>
We use the following types of Cookies and similar technologies:
</h5>
<h5 className={classes.sideheadings}>● Strictly necessary Cookies:
 </h5> 
<h5 className={classes.description}> Some Cookies are strictly necessary to make
our Services available to you; for example, to provide login functionality, user
authentication and security. We cannot provide you with the Services without
this type of Cookie.
</h5>

<h5 className={classes.sideheadings}>Functional Cookies: 
 </h5> 
<h5 className={classes.description}>These are used to recognize you when you return to our
Website. This enables us to personalize our content for you and remember
your preferences (for example, your choice of language).
 
</h5>
<h5 className={classes.sideheadings}>4. WITH WHOM WE SHARE YOUR PERSONAL INFORMATION
 </h5> 
<h5 className={classes.description}> We share your Personal Information with selected third parties, including:
</h5>
<h5 className={classes.sideheadings}>● Other users 
 </h5> 
<h5 className={classes.description}>  who see your Personal Information (such as your username and
email) and any other information you choose to share with them through the
Services.
</h5>
<h5 className={classes.sideheadings}>● Vendors and service providers we rely on for the provision of the
Services, 
 </h5> 
<h5 className={classes.description}> for example:
</h5>
<h5 className={classes.sideheadings}>• Cloud service providers
 </h5> 
<h5 className={classes.description}>who we rely on for compute and data storage, including
Amazon Web Services, based in the United States.
 
</h5>
<h5 className={classes.sideheadings}>• Platform support providers
 </h5> 
<h5 className={classes.description}> who help us manage and monitor the Services
</h5>      

<h5 className={classes.sideheadings}>• Mobile advertising tracking providers 
 </h5> 
<h5 className={classes.description}>who help us measure our advertising
effectiveness
</h5>  
<h5 className={classes.sideheadings}>• Analytics providers
 </h5> 
<h5 className={classes.description}> who provide analytics, segmentation and mobile measurement
services and help us understand our user base. We work with a number of analytics
providers, including Google LLC, which is based in the U.S. You can learn about
Google’s practices by going to https://www.google.com/policies/privacy/partners/, and
opt-out of them by downloading the Google Analytics opt-out browser add-on, available
at https://tools.google.com/dlpage/gaoptout.
 
</h5>
<h5 className={classes.sideheadings}>5. HOW LONG WE STORE YOUR INFORMATION
 </h5> 
<h5 className={classes.description}> scriver.in stores all Personal Information for as long as necessary to fulfill the purposes
set out in this Policy, or for as long as we are required to do so by law or in order to
comply with a regulatory obligation. When deleting Personal Information, we will take
measures to render such Personal Information irrecoverable or irreproducible, and the
electronic files which contain Personal Information will be permanently deleted.

</h5>  
<h5 className={classes.sideheadings}>6. YOUR RIGHTS
 </h5> 
<h5 className={classes.description}> In certain circumstances you have the following rights in relation to your Personal
Information that we hold.
</h5>  
<h5 className={classes.sideheadings}>● Access. 
 </h5> 
<h5 className={classes.description}>You have the right to access the Personal Information we hold about
you, and to receive an explanation of how we use it and who we share it with.
</h5>  
<h5 className={classes.sideheadings}>● Correction.
 </h5> 
<h5 className={classes.description}> You have the right to correct any Personal Information we hold
about you that is inaccurate or incomplete. 
</h5>  
<h5 className={classes.sideheadings}>● Erasure.
 </h5> 
<h5 className={classes.description}>You have the right to request for your Personal Information to be
erased or deleted.
 
</h5>  
<h5 className={classes.sideheadings}>● Object to processing. 
 </h5> 
<h5 className={classes.description}>  You have the right to object to our processing of your
Personal Information where we are relying on a legitimate interest or if we are
processing your Personal Information for direct marketing purposes.

</h5>  
<h5 className={classes.sideheadings}>● Restrict processing. 
 </h5> 
<h5 className={classes.description}>You have a right in certain circumstances to stop us
from processing your Personal Information other than for storage purposes.
 
</h5>  
<h5 className={classes.sideheadings}>● Portability.
 </h5> 
<h5 className={classes.description}>You have the right to receive, in a structured, commonly used and
machine-readable format, Personal Information that you have provided to us if
we process it on the basis of our contract with you, or with your consent, or to
request that we transfer such Personal Information to a third party. 
</h5>  
<h5 className={classes.sideheadings}>● Withdraw consent. 
 </h5> 
<h5 className={classes.description}>You have the right to withdraw any consent you
previously applied to us. We will apply your preferences going forward, and
this will not affect the lawfulness of processing before your consent was given.
</h5>  
 
<h5 className={classes.description}> Please note that, prior to any response to the exercise of such rights, we will require you
to verify your identity. In addition, we may require additional information (for example,
why you believe the information we hold about you is inaccurate or incomplete) and
may have valid legal reasons to refuse your request. We will inform you if that is the
case. For more information on how to exercise your rights, or to exercise your rights,
please email contact@scriver.in

</h5>    
<h5 className={classes.sideheadings}>7. CONTACT & COMPLAINTS
 </h5> 
<h5 className={classes.description}>For inquiries or complaints regarding this Policy, please first contact us at
contact@scriver.in and we will endeavor to deal with your complaint as soon as
possible. This is without prejudice to your right to launch a claim with a data protection
authority. 
</h5>  
<h5 className={classes.sideheadings}>8. DATA SECURITY
 </h5> 
<h5 className={classes.description}> We use certain physical, managerial, and technical safeguards that are designed to
improve the integrity and security of Personal Information that we collect and maintain.
However, the transfer of Personal Information through the internet will carry its own
inherent risks and we do not guarantee the security of your data transmitted through the
internet. You make any such transfer at your own risk.
The Website and Service may provide features or links to websites and services
provided by third parties. Any information you provide on Apps, third-party websites or
services is provided directly to the operators of such websites or services and is subject
to their policies governing privacy and security, even if accessed via our Website or in
connection with our Service.
</h5>  
<h5 className={classes.sideheadings}>9. CROSS-BORDER DATA TRANSFERS
 </h5> 
<h5 className={classes.description}> To facilitate our global operations, scriver.in may transfer, store and process your
operations with our partners and service providers based outside of the country in which
you are based. Laws in those countries may differ from the laws applicable to your
country of residence. Where we transfer, store and process your Personal Information
outside of the EEA or the UK we will ensure that the appropriate safeguards are in place
to ensure an adequate level of protection such as through acceding to the Standard
Contractual Clauses. Further details regarding the relevant safeguards can be obtained
from us on request.

</h5>  
<h5 className={classes.sideheadings}>10. CHANGES
 </h5> 
<h5 className={classes.description}>Where required, we will update this Policy from time to time. When we do so, we will
make it available on this page and indicate the date of the latest revision. Please check
this page frequently to see any updates or changes to this Policy.
 
</h5>  
<h5 className={classes.sideheadings}>11. ABOUT US
 </h5> 
<h5 className={classes.description}> If you have any questions, comments or concerns about our Privacy Policy, you may
contact us by email at contact@scriver.in
</h5>  
<h5 className={classes.sideheadings}>Terms of Use:
 </h5> 
<h5 className={classes.description}>By accessing this web site, you are agreeing to be bound by these web site Terms and
Conditions of Use, all applicable laws and regulations, and agree that you are
responsible for compliance with any applicable local laws. 
</h5>  

<h5 className={classes.description}> 1 . Although we try to provide the best, we are not responsible in any way if transcribed
results might not be accurate, because this depends on the quality of audio and accent
of the speaker too .</h5>
<h5 className={classes.description}>
2. We have linked all your notes to google drive to maintain your privacy and prevent
unexpected loss of data. We will not be responsible for any data loss or inaccuracies.
</h5>
<h5 className={classes.description}>
3. Warranty or assurance for any purpose is not guaranteed.
</h5>  
 




        </Grid>
        </Grid>
      
    </div>
  );
}
