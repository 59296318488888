import React, { useState } from "react";
import LeftDrawer from "../components/LeftDrawer";
import HomePageCss from "./css/HomePageCss";
import CenterPage from "./CenterPage";

export default function HomePage(props) {
  const classes = HomePageCss();
  const [minimized, setMinimized] = useState(false);
  const [URL, setURL] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedFile, setSelectedFile] = useState({});

  function URLSetter(fileId) {
    setURL("https://docs.google.com/document/d/" + fileId + "/");
  }

  function toggleButton() {
    if (!minimized) setMinimized(true);
    else setMinimized(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.home}>
        <div className={minimized ? classes.minLeftDrawer : classes.leftDrawer}>
          <LeftDrawer
            minimized={minimized}
            docs={props.docs}
            folders={props.folders}
            setFolders={props.setFolders}
            setDocs={props.setDocs}
            toggleButton={toggleButton}
            activeFile={props.activeFile}
            accessToken={props.accessToken}
            URL={URL}
            setURL={setURL}
            setFileURL={setFileURL}
            setSelectedFolder={setSelectedFolder}
            setSelectedFile={setSelectedFile}
            selectedFolder={selectedFolder}
            selectedFile={selectedFile}
          />
        </div>
        <div
          style={{
            marginLeft: minimized ? "40px" : "240px",
            // backgroundColor: "#EFE2BA",
            backgroundColor: "#ffffff",
            // backgroundColor: "#fff8e1",
            // backgroundColor: "#e3f2fd",
          }}
        >
          <CenterPage
            isLoggedIn={props.isLoggedIn}
            minimized={minimized}
            docs={props.docs}
            folders={props.folders}
            name={props.name}
            email={props.email}
            url={props.image}
            logout={props.logout}
            activeFile={props.activeFile}
            setActiveFile={props.setActiveFile}
            accessToken={props.accessToken}
            URL={URL}
            fileURL={fileURL}
            URLSetter={URLSetter}
            setSelectedFolder={setSelectedFolder}
            setSelectedFile={setSelectedFile}
            selectedFolder={selectedFolder}
            selectedFile={selectedFile}
          />
        </div>
      </div>
    </div>
  );
}
