import React, { useState, useEffect } from "react";
import {
  Paper,
  InputBase,
  IconButton,
  ListItem,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CenterPageCss from "../pages/css/CenterPageCss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LogOutBox from "./LogoutBox";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import RecordAudioButton from "./RecordAudioButton";
import AudioVideoDialog from "./AudioVideoDialog";
import SpeechRecognition from "react-speech-recognition";

var timer = null;
const Header = ({
  docs,
  folders,
  accessToken,
  url,
  name,
  email,
  logout,
  startListening,
  stopListening,
  abortListening,
  transcript,
  resetTranscript,
  minimized,
  URLSetter,
  selectedFolder,
  setSelectedFolder,
  selectedFile,
  setSelectedFile,
  URL,
  isLoggedIn,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");
  const classes = CenterPageCss();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileList, setFileList] = useState("");
  const [heading, setHeading] = useState("");
  const [buttonState, setButtonState] = useState(false);
  const [language, setLanguage] = React.useState("");
  const [folder, setFolder] = useState({});
  const [file, setFile] = useState({});
  const [uploadAudioDisabled, setUploadAudio] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getUnprocessedStatus();
  }, [isLoggedIn]);

  function handleRecordUpload() {
    startListening({ language: language.code });
    setButtonState(true);
  }

  const startTimer = () => {
    timer = setInterval(getUnprocessedStatus, 1800000);
    setUploadAudio(true);
  };

  const getUnprocessedStatus = () => {
    if (isLoggedIn) {
      fetch("https://scriver.in/transcribe_app/getUnprocessedTranscriptions/", {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "No unprocessed transcriptions") return;
          if (data.transcriptionStatus === 1) {
            uploadDoc(data.fileId, data.transcription);
          }
          if (data.transcriptionStatus === 0) {
            clearInterval(timer);
            setUploadAudio(true);
            startTimer();
          }
        });
    }
  };

  const uploadDoc = (fileId, text) => {
    var bearer = "Bearer " + accessToken;
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", bearer);
    var url = "https://docs.googleapis.com/v1/documents/" + fileId;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((docData) => {
        var length = docData.body.content.length;
        var lastIndex = docData.body.content[length - 1].endIndex;
        var bearer = "Bearer " + accessToken;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", bearer);
        // var trans = Object.values(data);
        var raw = {
          requests: [
            {
              insertText: {
                location: {
                  index: lastIndex - 1,
                },
                text: text,
              },
            },
          ],
        };
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(raw),
          redirect: "follow",
        };
        var url =
          "https://docs.googleapis.com/v1/documents/" +
          file.fileId +
          ":batchUpdate";
        fetch(url, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            fetch("https://scriver.in/transcribe_app/closeTranscription/", {
              method: "POST",
              credentials: "include",
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.remainingQuota > 0) setUploadAudio(false);
                else setUploadAudio(true);
              });
          });
      })
      .catch((err) => {
        setUploadAudio(false);
      });
  };
  function handleRecordClick() {
    if (!buttonState) {
      setDialogOpen(true);
      setHeading("Record Audio");
      abortListening();
    } else {
      abortListening();
      setButtonState(false);
      var bearer = "Bearer " + accessToken;
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", bearer);
      var url = "https://docs.googleapis.com/v1/documents/" + file.fileId;
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((docData) => {
          var length = docData.body.content.length;
          var lastIndex = docData.body.content[length - 1].endIndex;
          var bearer = "Bearer " + accessToken;
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", bearer);
          var raw = {
            requests: [
              {
                insertText: {
                  location: {
                    index: lastIndex - 1,
                  },
                  text: transcript,
                },
              },
            ],
          };
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: "follow",
          };
          var url =
            "https://docs.googleapis.com/v1/documents/" +
            file.fileId +
            ":batchUpdate";
          fetch(url, requestOptions)
            .then((response) => {
              return response.json();
            })
            .then((data) => {});
        });
    }
  }

  const uploadProps = {
    beforeUpload: (file) => {
      setFileList(file);
      setHeading("Import Audio/Video");
      setDialogOpen(true);
      return false;
    },
  };

  function onTextChanged(e) {
    const value = e.target.value;
    let recommendList = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      let fieldNames = docs.map((doc) => doc.fileName);
      recommendList = fieldNames.sort().filter((v) => regex.test(v));
    }
    setSuggestions(recommendList);
    setText(value);
  }

  function suggestionSelected(value) {
    setText(value);
    var fileId;
    var folderId;
    docs.map((doc) => {
      if (doc.fileName === value) {
        fileId = doc.fileId;
        URLSetter(fileId);
        setSelectedFile(doc);
        folderId = doc.folderId;
        folders.map((folder) => {
          if (folder.folderId === folderId) setSelectedFolder(folder);
          return null;
        });
      }
      return null;
    });
    setSuggestions([]);
  }

  function renderSuggestions() {
    const list = suggestions;
    if (list.length === 0) {
      return null;
    }
    return (
      <Paper
        square
        style={{
          width: "308px",
          height: "100%",
          borderRadious: "0px",
          margin: "auto",
        }}
      >
        {list.map((item) => (
          <div>
            <ListItem
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Button
                style={{
                  minWidth: "100%",
                }}
                onClick={() => suggestionSelected(item)}
              >
                <Typography
                  style={{
                    fontFamily: "system-ui",
                    fontSize: "0.8rem",
                    textTransform: "capitalize",
                  }}
                >
                  {item}
                </Typography>
              </Button>
            </ListItem>
          </div>
        ))}
      </Paper>
    );
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div
      style={
        minimized
          ? {
              height: "63px",
              backgroundColor: "#ffffff",
              width: "90%",
              position: "fixed",
              top: 0,
              paddingTop: "15px",
              paddingLeft: "5%",
              paddingRight: "5%",
            }
          : {
              height: "63px",
              backgroundColor: "#ffffff",
              position: "fixed",
              top: 0,
              width: "72%",
              paddingTop: "15px",
              paddingLeft: "5%",
              paddingRight: "5%",
            }
      }
    >
      <Paper square style={{ height: "63px", backgroundColor: "#fafafa" }}>
        <Grid
          container
          style={{
            paddingTop: "7.5px",
            paddingBottom: "7.5px",
            display: "flex",
          }}
        >
          <Grid
            item
            style={{
              flex: 13,
              paddingTop: "6.4px",
            }}
          >
            <Paper sqaure component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search Notes"
                style={{ fontFamily: "system-ui", fontSize: "0.8rem" }}
                onChange={onTextChanged}
                value={text}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Grid item style={{ zIndex: "1000" }}>
              {renderSuggestions()}
            </Grid>
          </Grid>
          <Grid item style={{ flex: 3 }}>
            <RecordAudioButton
              folders={folders}
              docs={docs}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              heading={heading}
              setHeading={setHeading}
              buttonState={buttonState}
              setButtonState={setButtonState}
              handleRecordUpload={handleRecordUpload}
              handleRecordClick={handleRecordClick}
              folder={folder}
              file={file}
              language={language}
              setFolder={setFolder}
              setFile={setFile}
              setLanguage={setLanguage}
            />
          </Grid>
          <Grid item style={{ flex: 5 }}>
            <Upload showUploadList={false} {...uploadProps}>
              <Button
                variant="outlined"
                type="primary"
                size="medium"
                className={classes.uploadButton}
                startIcon={<UploadOutlined />}
                disabled={uploadAudioDisabled}
              >
                {uploadAudioDisabled === false
                  ? "Import Audio/video"
                  : "Processing"}
              </Button>
            </Upload>

            <AudioVideoDialog
              startTimer={startTimer}
              fileList={fileList}
              setFileList={setFileList}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              docs={docs}
              folders={folders}
              accessToken={accessToken}
              heading={heading}
              buttonState={buttonState}
              setButtonState={setButtonState}
              handleRecordUpload={handleRecordUpload}
              folder={folder}
              file={file}
              language={language}
              setFolder={setFolder}
              setFile={setFile}
              setLanguage={setLanguage}
            />
          </Grid>
          <Grid item style={{ flex: 2 }}>
            <div className={classes.login}>
              <Button
                size="small"
                style={{ marginTop: "8px" }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <Avatar
                  alt="Logout"
                  src={url}
                  style={{ width: "24px", height: "24px" }}
                />
                {open ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </Button>
              {open ? (
                <LogOutBox
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  email={email}
                  name={name}
                  logout={logout}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const options = {
  autoStart: false,
};

export default SpeechRecognition(options)(Header);
