import React, { useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography,
  Divider,
  DialogActions,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import SpeechRecognition from "react-speech-recognition";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
  },
  formControl: {
    minWidth: 120,
    height: "35px",
    marginTop: "0px",
  },
  textField: {
    fontFamily: "system-ui",
    fontSize: "0.7rem",
    marginTop: "0px",
  },
  label: {
    display: "block",
    marginTop: "-10px",
    fontSize: "0.7rem",
    fontFamily: "system-ui",
  },
}));

let file = null;

const AudioVideoDialog = ({
  folders,
  docs,
  setDialogOpen,
  fileList,
  setFileList,
  accessToken,
  dialogOpen,
  heading,
  handleRecordUpload,
  folder,
  file,
  language,
  setFolder,
  setLanguage,
  setFile,
  startTimer,
}) => {
  const classes = useStyles();
  const [docOptions, setDocOptions] = useState([]);

  const RecordLanguageOptions = [
    { title: "English-US", code: "EN-US" },
    { title: "English-UK", code: "EN-UK" },
    { title: "Korean", code: "ko" },
    { title: "Spanish", code: "es-ES" },
  ];
  const UploadLanguageOptions = [
    { title: "English-US", code: "EN-US" },
    { title: "English-UK", code: "EN-UK" },
    { title: "English-IN", code: "EN-IN" },
  ];

  const handleLanguageChange = (event) => {
    if (heading === "Record Audio") {
      RecordLanguageOptions.map((language) => {
        if (language.title === event.target.value) {
          setLanguage({ title: language.title, code: language.code });
        }
        return null;
      });
    } else {
      UploadLanguageOptions.map((language) => {
        if (language.title === event.target.value) {
          setLanguage({ title: language.title, code: language.code });
        }
        return null;
      });
    }
  };

  const handleFolderChange = (event) => {
    folders.map((currentFolder) => {
      if (currentFolder.folderName === event.target.value) {
        var currentFolderId = currentFolder.folderId;
        setFolder({
          folderId: currentFolder.folderId,
          folderName: currentFolder.folderName,
        });
        var files = [{ fileName: "No file Available" }];
        docs.map((doc) => {
          if (doc.folderId === currentFolderId) {
            files.push({
              folderId: doc.folderId,
              fileName: doc.fileName,
              fileId: doc.fileId,
            });
          }
          return null;
        });
        if (files.length !== 1) files.shift();
        setDocOptions(files);
      }
      return null;
    });
  };

  const handleFileChange = (event) => {
    file = { fileId: "", fileName: "", folderId: "" };
    docOptions.map((docOption) => {
      if (docOption.fileName === event.target.value) {
        file.fileId = docOption.fileId;
        file.fileName = docOption.fileName;
        file.folderId = docOption.folderId;
      }
      return null;
    });
    setFile(file);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  function handleUpload() {
    setDialogOpen(false);
    if (heading === "Record Audio") {
      handleRecordUpload();
    } else if (heading === "Import Audio/Video") {
      const formData = new FormData();
      // fileList.forEach((file) => {
      console.log(file);
      formData.append("blobFile", fileList);
      formData.append("userId", "Test");
      formData.append("fileId", file.fileId);
      formData.append("language", "en-US");
      // });

      // setUploading(true);

      // You can use any AJAX library you like
      fetch("https://scriver.in/transcribe_app/uploadAudio/", {
        method: "POST",
        credentials: "include",
        body: formData,
      })
        .then((response) => {
          if (response.status === 400) {
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setFileList("");
          startTimer();
          // var bearer = "Bearer " + accessToken;
          // var myHeaders = new Headers();
          // myHeaders.append("Accept", "application/json");
          // myHeaders.append("Content-Type", "application/json");
          // myHeaders.append("Authorization", bearer);
          // var url = "https://docs.googleapis.com/v1/documents/" + file.fileId;
          // var requestOptions = {
          //   method: "GET",
          //   headers: myHeaders,
          //   redirect: "follow",
          // };
          // fetch(url, requestOptions)
          //   .then((response) => {
          //     return response.json();
          //   })
          //   .then((docData) => {
          //     var length = docData.body.content.length;
          //     var lastIndex = docData.body.content[length - 1].endIndex;
          //     var bearer = "Bearer " + accessToken;
          //     var myHeaders = new Headers();
          //     myHeaders.append("Content-Type", "application/json");
          //     myHeaders.append("Accept", "application/json");
          //     myHeaders.append("Authorization", bearer);
          //     var trans = Object.values(data);
          //     var raw = {
          //       requests: [
          //         {
          //           insertText: {
          //             location: {
          //               index: lastIndex - 1,
          //             },
          //             text: trans[0],
          //           },
          //         },
          //       ],
          //     };
          //     var requestOptions = {
          //       method: "POST",
          //       headers: myHeaders,
          //       body: JSON.stringify(raw),
          //       redirect: "follow",
          //     };
          //     var url =
          //       "https://docs.googleapis.com/v1/documents/" +
          //       file.fileId +
          //       ":batchUpdate";
          //     fetch(url, requestOptions)
          //       .then((response) => {
          //         return response.json();
          //       })
          //       .then((data) => {});
          //   });
        });
    }
  }

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "20px",
            marginBottom: "10px",
            width: "350px",
          }}
        >
          <Grid item>
            <Typography
              style={{
                fontFamily: "system-ui",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {heading}
            </Typography>
          </Grid>
          <Divider
            style={{ marginTop: "5px" }}
            orientation="horizontal"
            variant="fullWidth"
          />
          <form className={classes.container}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Typography
                style={{
                  flex: 2,
                  fontFamily: "system-ui",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  marginTop: "5px",
                }}
              >
                Language
              </Typography>
              <FormControl style={{ flex: 7 }} className={classes.formControl}>
                <InputLabel
                  htmlFor="demo-dialog-native"
                  style={{
                    marginTop: "-10px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  Language
                </InputLabel>
                <Select
                  native
                  value={language.title}
                  onChange={handleLanguageChange}
                  input={<Input id="demo-dialog-native" />}
                  variant="filled"
                  style={{
                    height: "35px",
                    marginTop: "0px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  {heading === "Record Audio"
                    ? RecordLanguageOptions.map((language) => (
                        <option
                          key={language.code}
                          value={language.title}
                          selected={true}
                        >
                          {language.title}
                        </option>
                      ))
                    : UploadLanguageOptions.map((language) => (
                        <option
                          key={language.code}
                          value={language.title}
                          selected={true}
                        >
                          {language.title}
                        </option>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  flex: 2,
                  fontFamily: "system-ui",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  marginTop: "5px",
                }}
              >
                Folder
              </Typography>
              <FormControl style={{ flex: 7 }} className={classes.formControl}>
                <InputLabel
                  id="demo-dialog-select-label"
                  style={{
                    marginTop: "-10px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  Folder
                </InputLabel>
                <Select
                  native
                  value={folder.folderName}
                  onChange={handleFolderChange}
                  input={<Input id="demo-dialog-native" />}
                  variant="filled"
                  defaultValue="Select Folder"
                  style={{
                    height: "35px",
                    marginTop: "0px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  <option>Choose Folder</option>
                  {folders &&
                    folders.map((folderTemp) => (
                      <option
                        key={folderTemp.folderId}
                        value={folderTemp.folderName}
                      >
                        {folderTemp.folderName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  flex: 2,
                  fontFamily: "system-ui",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  marginTop: "5px",
                }}
              >
                Document
              </Typography>
              <FormControl style={{ flex: 7 }} className={classes.formControl}>
                <InputLabel
                  id="demo-dialog-select-label"
                  style={{
                    marginTop: "-10px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  Document
                </InputLabel>
                <Select
                  native
                  value={file.fileName}
                  onChange={handleFileChange}
                  input={<Input id="demo-dialog-native" />}
                  variant="filled"
                  defaultValue=""
                  style={{
                    height: "35px",
                    marginTop: "0px",
                    fontSize: "0.7rem",
                    fontFamily: "system-ui",
                  }}
                >
                  <option>Choose File</option>
                  {docOptions.map((docOption) => (
                    <option key={docOption.fileId} value={docOption.fileName}>
                      {docOption.fileName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </form>
        </Grid>
        <DialogActions>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancel}
                style={{ textTransform: "capitalize" }}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "10px", flex: 1 }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  color: "#ffffff",
                  background: "rgb(55, 40, 87)",
                }}
                type="primary"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const options = {
  autoStart: false,
};

export default SpeechRecognition(options)(AudioVideoDialog);
