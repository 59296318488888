import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import { Grid, Typography, Button, Paper, Avatar } from "@material-ui/core";
import LandingPageCss from "./../pages/css/LandingPageCss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "./../images/logo1Y.png";
import LogOutBox from "./LogoutBox";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ClearIcon from '@material-ui/icons/Clear';
import { styled } from '@material-ui/core/styles';


const drawerWidth = 240;
const ColorAppBar = styled(AppBar)({
  background: 'rgb(55,40,87)',
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function HeaderLanding(props) {
  const [scrolled, setScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let pathname = useLocation().pathname;

  function handleScroll() {
    var yScroll = window.pageYOffset;
    if (yScroll > 100) setScrolled(true);
    else setScrolled(false);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const classes = LandingPageCss();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    const classes1 = useStyles();
    const theme = useTheme();
    const [open1, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);  
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Hidden smDown>
      <Paper
        style={
          scrolled
            ? {
                overflow: "hidden",
                backgroundColor: "#f3f7f8",
                position: "fixed",
                zIndex: 1001,
                top: "0",
                height: "60px",
                width: "80%",
                paddingLeft: "10%",
                paddingRight: "10%",
                color: "#666666",
              }
            : {
                overflow: "hidden",
                backgroundColor: "#372857",
                position: "fixed",
                zIndex: 1001,
                height: "60px",
                top: "0",
                width: "80%",
                paddingLeft: "10%",
                paddingRight: "10%",
                color: "#ffffff",
              }
        }
        square
        elevation={0}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.header}
        >
          <Grid item style={{ flex: 4 }}>
            
            <Link style={{ textDecoration: "none" }} to="/">
              <img
                src={logo}
                alt="logo"
                style={{ height: 45, paddingTop: "5px", width: 150 }}
              ></img>
            </Link>
            
          </Grid>
          <Grid item style={{ flex: 3 }}>
          
            <Link style={{ textDecoration: "none" }} to="/product">
              <Button
                size="medium"
                style={{
                  textTransform: "capitalize",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  fontSize: "16px",
                }}
              >
                <Typography
                  style={
                    pathname === "/product"
                      ? { color: "rgb(251, 179, 27)" }
                      : scrolled && pathname !== "/product"
                      ? { color: "black" }
                      : { color: "white" }
                  }
                >
                  Product
                </Typography>
              </Button>
            </Link>
            
          </Grid>
          <Grid item style={{ flex: 3 }}>
          
            <Link style={{ textDecoration: "none" }} to="/resources">
              <Button
                size="medium"
                style={{
                  textTransform: "capitalize",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  fontSize: "16px",
                }}
              >
                <Typography
                  style={
                    pathname === "/resources"
                      ? { color: "rgb(251, 179, 27)" }
                      : scrolled && pathname !== "/resources"
                      ? { color: "black" }
                      : { color: "white" }
                  }
                >
                  Resources
                </Typography>
              </Button>
            </Link>
            
          </Grid>
          <Grid item style={{ flex: 7 }}>
            
            <Link style={{ textDecoration: "none" }} to="/contact">
              <Button
                size="medium"
                style={{
                  textTransform: "capitalize",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  fontSize: "16px",
                }}
              >
                <Typography
                  style={
                    pathname === "/contact"
                      ? { color: "rgb(251, 179, 27)" }
                      : scrolled && pathname !== "/contact"
                      ? { color: "black" }
                      : { color: "white" }
                  }
                >
                  FAQ's
                </Typography>
              </Button>
            </Link>
           
          </Grid>
          
          <Grid item style={{ flex: 1 }}>
            {props.email === "" ? (
              <GoogleLogin
                clientId="888778210177-4hl5k16puj0ne1ebsm4pjkisrs333imn.apps.googleusercontent.com"
                render={(renderProps) => (
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={renderProps.onClick}
                    className={classes.button}
                    style={
                      scrolled
                        ? { color: "black", textTransform: "capitalize" }
                        : { color: "#ffffff", textTransform: "capitalize" }
                    }
                  >
                    Login/SignUp
                  </Button>
                )}
                buttonText="Login"
                onSuccess={props.responseGoogle}
                isSignedIn={true}
                onFailure={props.responseGoogle}
                cookiePolicy={"single_host_origin"}
                scope="profile email https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file"
              />
            ) : (
              <div className={classes.login}>
                <Button
                  size="small"
                  style={{ marginTop: "8px" }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <Avatar
                    alt="Logout"
                    src={props.image}
                    style={{ width: "24px", height: "24px" }}
                  />
                  {open ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </Button>
                {open ? (
                  <LogOutBox
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    email={props.email}
                    name={props.name}
                    logout={props.logout}
                  />
                ) : null}
              </div>
            )}
            
          </Grid>
          
        </Grid>
      </Paper>
      </Hidden>
      <Hidden only={['md','lg','xl']}>
  <div className={classes1.root}>
      <CssBaseline />
      <ColorAppBar
     color="primary"
      position="fixed"
        className={clsx(classes1.appBar, {
          [classes1.appBarShift]: open1,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes1.menuButton, open1 && classes1.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Menu
          </Typography>
        </Toolbar>
      </ColorAppBar>
      <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={open1}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        <div className={classes1.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ClearIcon /> : <ClearIcon />}
          </IconButton>
        </div>
        <Divider /> 
        <Grid item style={{ flex: 7 }}>
            
        <Button href="/#/login">Home</Button>
        <Divider/> 
        <Button href="/#/product">Product</Button>
        <Divider/>
        <Button href="/#/userguide">Resources</Button>
        <Divider/>
        <Button href="/#/contact">Contact</Button>
        <Divider/>
        <Button href="/#/privacy" target="_blank">Privacy Policy</Button>
        <Divider />
        <Divider />
        </Grid>
      
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open1,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
    </Hidden>
    </div>
  );
      }