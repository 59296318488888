import React from "react";
import HeaderLanding from "./HeaderLanding";
import classNames from "classnames";
import { Grid, Typography, Hidden } from "@material-ui/core";
import Parallax from "./Parallax";
import PrivacyComponent from "../components/PrivacyComponent";
import styles from "./../assets/FeaturePageStyles";

export default function ProductBox(props) {
  const classes = styles();

  return (
    <div style={{ backgroundColor: "#FFFAFA" }}>
      <HeaderLanding
        name={props.name}
        email={props.email || ""}
        image={props.image}
        logout={props.logout}
        setName={props.setName}
        setEmail={props.setEmail}
      />
      <Grid >
        <Hidden mdDown>
      <Parallax>
        <div className={classes.container}>
          <Grid Container>
            <Grid Item xs={12} sm={12} md={6}>
              <h1 className={classes.title}>PrivacyPolicy</h1>
              <br />
            </Grid>
          </Grid>
        </div>
      </Parallax>
      </Hidden>
      </Grid>
      <div
        style={{ marginBottom: "50px" }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div>
          <PrivacyComponent />
        </div>
      </div>
      <Typography
        style={{
          color: "#999999",
          fontFamily: "Lato-Regular",
          fontSize: "18px",
          paddingBottom: "20px",
          paddingLeft: "40%",
        }}
      >
        © 2020. All Rights Reserved
      </Typography>
    </div>
  );
}
