import React, { useState } from "react";
import HomePage from "./pages/HomePage";
import LandingPage from "./pages/LandingPage";

import AppCss from "./AppCss";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Contact from "./components/FAQ";
import ProductBox from "./components/ProductBox";
import UserGuides from "./pages/UserGuides.js";
import AccountSettings from "./components/AccountSettings";
import Privacy from "../src/components/Privacy";
import UserGuideMobile from "../src/pages/UserGuideMobile";
function App() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [folders, setFolders] = useState([]);
  const [docs, setDocs] = useState([]);
  const [activeFile, setActiveFile] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const logout = () => {
    fetch("https://scriver.in/transcribe_app/logout/", {
      method: "POST",
      credentials: "include",
    }).then(() => {
      setName("");
      setEmail("");
      setImage("");
    });
  };

  function responseGoogle(response) {
    setEmail(response.profileObj.email);
    setName(response.profileObj.name);
    setImage(response.profileObj.imageUrl);
    var Token = String(response.tokenObj.access_token);
    setAccessToken(Token);
    fetch("https://scriver.in/transcribe_app/login/", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        tokenId: response.tokenObj.id_token,
        appType: "WEBAPP",
      }),
    }).then(() => {
      setLoggedIn(true);
      fetch("https://scriver.in/transcribe_app/getFolders/", {
        method: "POST",
        credentials: "include",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setFolders(data);
        });
      fetch("https://scriver.in/transcribe_app/getFiles/", {
        method: "POST",
        credentials: "include",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setDocs(data);
        });
      fetch("https://scriver.in/transcribe_app/getProfile/", {
        method: "POST",
        credentials: "include",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.name !== undefined) setName(data.name + "");
          if (data.email !== undefined) {
            setEmail(data.email + "");
          }
          setActiveFile(data.lastActiveFile);
        });
    });
  }

  const classes = AppCss();
  return (
    <div className={classes.root}>
      <Router>
        <Switch>
          <Route path={"/contact"}>
            {(email + "").length === 0 ? (
              <Contact />
            ) : (
              <Contact
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/privacy"}>
            {(email + "").length === 0 ? (
              <Privacy />
            ) : (
              <Privacy
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/userguide"}>
            {(email + "").length === 0 ? (
              <UserGuideMobile />
            ) : (
              <UserGuideMobile
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/product"}>
            {(email + "").length === 0 ? (
              <ProductBox />
            ) : (
              <ProductBox
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/resources"}>
            {(email + "").length === 0 ? (
              <UserGuides />
            ) : (
              <UserGuides
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/settings"}>
            {(email + "").length === 0 ? (
              <AccountSettings />
            ) : (
              <AccountSettings
                email={email}
                name={name}
                image={image}
                logout={logout}
                setEmail={setEmail}
                setName={setName}
              />
            )}
          </Route>
          <Route path={"/login"}>
            <LandingPage
              responseGoogle={responseGoogle}
              logout={logout}
              email={email}
              name={name}
              image={image}
              setName={setName}
              setEmail={setEmail}
            />
          </Route>
          <Route path={"/"}>
            {email ? (
              <HomePage
                isLoggedIn={isLoggedIn}
                email={email}
                name={name}
                image={image}
                folders={folders}
                setFolders={setFolders}
                docs={docs}
                setDocs={setDocs}
                logout={logout}
                activeFile={activeFile}
                setActiveFile={setActiveFile}
                accessToken={accessToken}
              />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
