import React from "react";
import {
  Paper,
  Typography,
  MobileStepper,
  Button,
  Avatar,
  Grid,
} from "@material-ui/core";
import ReviewBoxCss from "./css/ReviewBoxCss";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Quote from "./../images/quote.png";
import Customer1 from "./../images/Customer1.jpg";
import Customer2 from "./../images/Customer2.jpg";
import Customer3 from "./../images/Customer3.JPG";
import Customer4 from "./../images/Customer4.jpg";
import Customer5 from "./../images/Customer5.JPG";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [
  {
    label: "Feature 1 looks good",
    imgPath: Customer1,
    name: "Rama Chetan",
    profession: "Graduate Student",
  },
  {
    label: "Awesome Product",
    imgPath: Customer2,
    name: "Yashwanth",
    profession: "Electrical Engineer",
  },
  {
    label: "Awesome Product",
    imgPath: Customer3,
    name: "Krishna",
    profession: "Software Engineer",
  },
  {
    label: "Awesome Product",
    imgPath: Customer4,
    name: "Pruthvik",
    profession: "Senior Software Engineer",
  },
  {
    label: "Awesome Product",
    imgPath: Customer5,
    name: "Ruchith",
    profession: "Self Employed",
  },
];

export default function ReviewBox() {
  const classes = ReviewBoxCss();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <Typography
        style={{
          color: "#333333",
          fontFamily: "Lato-Medium",
          fontSize: "36px",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        What our customers say about us.
      </Typography>
      <div className={classes.root}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{ position: "relative" }}
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.name} style={{ position: "relative" }}>
              <img
                src={Quote}
                alt="quote"
                width="48px"
                height="48px"
                style={{
                  position: "absolute",
                  left: 20,
                  top: -5,
                  zIndex: "150",
                }}
              ></img>
              <Paper className={classes.header}>
                <Typography
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    fontFamily: "system-ui",
                    color: "#787878",
                  }}
                >
                  {tutorialSteps[activeStep].label}
                </Typography>
              </Paper>
              {Math.abs(activeStep - index) <= 2 ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{ marginTop: "30px" }}
                >
                  <Grid item>
                    <Avatar
                      alt={step.label}
                      src={step.imgPath}
                      className={classes.img}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontFamily: "system-ui",
                        color: "black",
                        fontWeight: "500",
                      }}
                    >
                      {step.name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "system-ui",
                        color: "#787878",
                      }}
                    >
                      {step.profession}
                    </Typography>
                  </Grid>
                  <Grid item style={{ color: "rgb(89, 200, 226)" }}>
                    <MobileStepper
                      variant="dots"
                      steps={maxSteps}
                      position="static"
                      activeStep={activeStep}
                      className={classes.stepper}
                      style={{ color: "rgb(89, 200, 226)" }}
                      nextButton={
                        <Button
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button
                          size="small"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
    </div>
  );
}