import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function WelcomeMessage() {
  return (
    <div style={{ marginTop: "100px", paddingTop: "50px" }}>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        style={{ paddingTop: "90px" }}
      >
        <Grid item>
          <Typography
            style={{
              fontFamily: "AvertaStd-Semibold",
              fontSize: "2.5rem",
              fontWeight: 600,
              maxWidth: "400px",
            }}
          >
            Welcome to Scriver
          </Typography>
        </Grid>
        <Grid item style={{ paddingTop: "20px", marginLeft: "10%" }}>
          <Typography
            style={{
              color: "#8294a5",
              fontFamily: "AvertaStd-Semibold",
              fontSize: "1.5rem",
              lineHeight: "2rem",
              maxWidth: "600px",
            }}
          >
            Get started by recording your next lecture, meeting, interview or an
            important conversion. To know more.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
