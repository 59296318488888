import React from "react";
import { Typography, Tabs, Tab, Box, Grid,AppBar, Hidden } from "@material-ui/core";
import UserGuidesCss from "./css/UserGuideCss";
import HeaderLanding from "../components/HeaderLanding";
import Parallax from "../components/Parallax";
// import ParallaxImage from "../images/7.svg";
import ug1 from "../images/ug1.png";
import ug2 from "../images/ug2.png";
import ug3 from "../images/ug3.png";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UserGuides(props) {
  const classes = UserGuidesCss();
  
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <HeaderLanding
        name={props.name}
        email={props.email || ""}
        logout={props.logout}
        image={props.image}
        setName={props.setName}
        setEmail={props.setEmail}
      />
      <Hidden smDown>
      <Parallax>
        <div className={classes.container}>
          <Grid Container>
            <Grid Item xs={12} sm={12} md={12}>
              <h1 className={classes.title}>User Guides</h1>
              {/* <h4>we have three products***</h4> */}
              <br />
            </Grid>
           
          </Grid>
        </div>
      </Parallax>
      </Hidden>
      <Hidden smUp>
        <div className={classes.container}
        style={{
          maxHeight: "92vh",
          marginTop: "35px",
          
          }}>
          <Grid Container>
            <Grid Item xs={12} sm={12} md={12}><br />
            </Grid>
          </Grid>
        </div>
        </Hidden>
      <div className={classes.positionRelative}>
      <AppBar position="relative" color="default">
      <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Live Notes" {...a11yProps(0)} />
          <Tab label="Voice/Speech to Text" {...a11yProps(1)} />
          <Tab label="Audio/Video to Text" {...a11yProps(2)} />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <img
            src={ug1}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <img
            src={ug2}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <img
            src={ug3}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
    
    </div>
 
  </div>
   );
}
