import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Divider, Grid, Typography, InputBase, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  field: {
    height: "25px",
    width: "500px",
    border: "solid 0px #rgb(55, 40, 87)",
    transition: "border-width 0.6s linear",
    padding: "2px 4px",
    margin: "auto",
    alignItems: "center",
    "&:hover": {
      borderWidth: "1px",
    },
  },
  multiField: {
    height: "175px",
    width: "559.8px",
    border: "solid 0px #rgb(55, 40, 87)",
    transition: "border-width 0.6s linear",
    padding: "2px 4px",
    alignItems: "center",
    "&:hover": {
      borderWidth: "1px",
    },
  },
}));

export default function ContactSupport(props) {
  const classes = useStyles();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  function handleSubjectChange(event) {
    setSubject({ value: event.target.value });
  }

  function handleMessageChange(event) {
    setMessage({ value: event.target.value });
  }

  function handleSubmit() {
    var formdata = new FormData();
    formdata.append("emailId", props.email);
    formdata.append("body", subject.value);
    formdata.append("message", message.value);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      "https://scriver.in/transcribe_app/contactForm/",
      requestOptions
    ).then((response) => response.text());
  }

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item style={{ marginLeft: "5px", marginTop: "5px" }}>
            <Typography
              style={{
                fontFamily: "system-ui",
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              Contact Support
            </Typography>
          </Grid>
          <Divider style={{ marginTop: "5px" }} />
          <Grid
            item
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              marginRight: "5px",
              height: "25px",
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ height: "25px" }}
            >
              <Grid
                item
                style={{
                  fontFamily: "system-ui",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                }}
              >
                From
              </Grid>
              <Grid item style={{ height: "25px", marginLeft: "30px" }}>
                <Paper component="form" square="true" className={classes.field}>
                  <InputBase
                    style={{
                      width: "480px",
                      fontSize: "0.8rem",
                      marginLeft: "6px",
                    }}
                    placeholder="Email"
                    value={props.email}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              height: "25px",
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ height: "25px" }}
            >
              <Grid
                item
                style={{
                  fontFamily: "system-ui",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                }}
              >
                Subject
              </Grid>
              <Grid item style={{ height: "25px", marginLeft: "17px" }}>
                <Paper component="form" square="true" className={classes.field}>
                  <InputBase
                    placeholder="Subject"
                    onChange={handleSubjectChange}
                    value={subject.value}
                    style={{
                      width: "480px",
                      fontSize: "0.8rem",
                      marginLeft: "6px",
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "10px",
              marginLeft: "5px",
              marginRight: "5px",
              height: "25px",
              fontSize: "0.8rem",
              fontWeight: 500,
            }}
          >
            Message
          </Grid>
          <Grid
            item
            style={{
              maxWidth: "100%",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <Paper
              component="form"
              square="true"
              className={classes.multiField}
            >
              <InputBase
                className={classes.input}
                value={message.value}
                onChange={handleMessageChange}
                placeholder=" Message... "
                multiline
              />
            </Paper>
          </Grid>
        </Grid>
        <DialogActions>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "300px",
              marginRight: "15px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={props.handleClose}
                style={{
                  textTransform: "capitalize",
                  color: "rgb(55, 40, 87)",
                }}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "10px", flex: 1 }}>
              <Button
                variant="contained"
                size="small"
                onClick={handleSubmit}
                style={{
                  textTransform: "capitalize",
                  background: "rgb(55, 40, 87)",
                  color: "#ffffff",
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
