import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQCss from "./css/FAQCss";
import HeaderLanding from "./HeaderLanding";
import ContactUs from "./ContactUs";

export default function FAQ(props) {
  const classes = FAQCss();

  const faqJson = [
    {
      question: "What can scriver be used  for?",
      answer:
        "Scriver can efficiently transcribe your online lectures and meetings into organized notes. You  can also effectively transcribe  your voice conversations and audio files into smart notes that you can easily search and share. You can use it to take notes at your meetings and interviews, capture your thoughts and ideas while you’re driving in the car, and transcribe your existing recordings and podcasts.  Students, professors,teachers,writers, bloggers, reporters or anyone who want to make their life and time valuable can use scriver." +
        " This advanced technology using speech recognition is also known to benefit students with special needs and learning disabilities that interfere with their ability to spell and write.",
    },
    // {
    //   question: "The Scriver Chrome Extension and What can be done with it ?",
    //   answer:
    //     "This flagship feature of Scriver, The Chrome extension can be used to record your live lectures and meetings which get transcribed  into high quality notes. This comes with an elegant add onn which gives you an exclusive feature of recording parts of lectures/meetings whenever you want to while listening to them parallely." 
    //     +"To know more about the exclusive features click here (link to this feature )and its usage click here(link to user guide)" 
        
    // },
     
    {
      question: "What is your privacy policy? Is my data safe?",
      answer:
        "We take your privacy seriously. We are committed to keeping your data private and secure. We do not sell,share, rent or exchange your information with anyone else. When we do collect data, we will use it to benefit you and to make your experiences better.",
    },
    {
      question: "What are the system requirements?",
      answer:
        "Scriver  uses a  powerful Speech Recognition engine to transcribe audio into text and the results are extremely fast.  You need Google Chrome browser on your Windows PC, Mac OS X or Linux. Scriver do not work inside Chrome on Android,  we recommend desktop browser only for improved productivity. It is not compatible with iPhone or iPad too.",
    },
    // {
    //   question: "How do I get started?",
    //   answer:
    //     "You can get started by downloading our chrome extension here (give link here). To know more about features provided have a look into our product page(link to features). To know how to use features have a  look into our resources page(linke here).",
    // },
    {
      question: "Is there a cost?",
      answer:
        "No. We are currently not charging for any of the services we are providing . Our features are completely available for free. ",
    },
    {
      question: "How long can I record for?",
      answer:
        "Our current Free Plan offers free recording upto 900 minutes per month, and limits each transcription to 40 minutes.",
    },
    {
      question: "How do I contact support or give feedback ?",
      answer:
        "Please enter your query/feedback down below.We will get back to you.",
    },
  ];

  return (
    <div className={classes.root}>
      <HeaderLanding
        name={props.name}
        email={props.email || ""}
        logout={props.logout}
        image={props.image}
        setName={props.setName}
        setEmail={props.setEmail}
      />
      <div style={{ marginTop: "125px" }} className={classes.faq}>
        <Typography
          style={{
            paddingBottom: "20px",
            fontFamily: "Roboto",
            fontSize: "2rem",
          }}
        >
          Welcome, and thank you for your interest in Scriver.
        </Typography>
        {faqJson.map((faq, i) => {
          return (
            <ExpansionPanel style={{ backgroundColor: "#eeeeee" }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {faq.question}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className={classes.answer}>{faq.answer}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
      <ContactUs />
    </div>
  );
}
