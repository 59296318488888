import React from "react";
import { GoogleLogout } from "react-google-login";
import SettingsIcon from "@material-ui/icons/Settings";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Popover, Grid, Typography, Button, Paper } from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ContactSupport from "./ContactSupport";
import { Link, useHistory } from "react-router-dom";

function LogoutBox(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  let history = useHistory();
  function logoutHandler() {
    props.logout().then(() => {
      history.push("/login");
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{
        width: "210px",
        zIndex: "1000",
        position: "absolute",
      }}
    >
      <Paper style={{ width: "210px" }}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item style={{ marginLeft: "15%", marginRight: "15%" }}>
                <Typography
                  style={{
                    fontSize: "0.7rem",
                    fontFamily: "AvertaStd-Bold",
                    fontWeight: "550",
                    fontColor: "#05294b",
                    paddingTop: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  {props.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "0.6rem",
                    fontFamily: "AvertaStd-Bold",
                    fontWeight: "400",
                    fontColor: "#05294b",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  }}
                >
                  {props.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Link
            to="/settings"
            style={{
              textDecoration: "none",
              minWidth: "178px",
            }}
          >
            <Button
              style={{
                paddingLeft: "10px",
                minWidth: "178px",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "row",
                color: "#05294b",
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <SettingsIcon
                  fontSize="medium"
                  style={{ marginRight: "10px" }}
                />
                <Typography
                  style={{
                    fontSize: "0.9rem",
                    fontFamily: "AvertaStd-Bold",
                    textTransform: "capitalize",
                  }}
                >
                  Acount Settings
                </Typography>
              </Grid>
            </Button>
          </Link>
          <Button
            style={{
              minWidth: "178px",
              paddingLeft: "10px",
              paddingRight: "10px",
              color: "#05294b",
              display: "flex",
              flexDirection: "row",
            }}
            onClick={handleClickOpen}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <ChatOutlinedIcon
                fontSize="medium"
                style={{ align: "left", marginRight: "10px" }}
              />
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "AvertaStd-Bold",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Contact Support
              </Typography>
            </Grid>
          </Button>
          <ContactSupport
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            email={props.email}
          />
          <Link
            to="/contact"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#05294b",
              minWidth: "178px",
            }}
          >
            <Button
              style={{
                paddingLeft: "10px",
                minWidth: "178px",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "row",
                color: "#05294b",
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <QuestionAnswerIcon
                  fontSize="medium"
                  style={{ color: "#05294b", marginRight: "10px" }}
                />
                <Typography
                  align="left"
                  style={{
                    fontSize: "0.9rem",
                    color: "#05294b",
                    fontFamily: "AvertaStd-Bold",
                    textTransform: "capitalize",
                  }}
                >
                  FAQ
                </Typography>
              </Grid>
            </Button>
          </Link>
          <GoogleLogout
            clientId="888778210177-4hl5k16puj0ne1ebsm4pjkisrs333imn.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                style={{
                  minWidth: "178px",
                  color: "#05294b",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <ExitToAppIcon
                    fontSize="medium"
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      fontSize: "0.9rem",
                      fontFamily: "AvertaStd-Bold",
                      fontWeight: "400",
                    }}
                  >
                    Logout
                  </Typography>
                </Grid>
              </Button>
            )}
            onLogoutSuccess={logoutHandler}
          ></GoogleLogout>
        </Grid>
      </Paper>
    </Popover>
  );
}

export default LogoutBox;
