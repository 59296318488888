import { makeStyles } from "@material-ui/styles";
import Background from "./../../images/2.svg";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    // backgroundColor: "#ffffff",
    height: "100%",
    backgroundImage: "url(" + { Background } + ")",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "8px",
  },
  logo: {
    fontSize: "36px",
    fontfamily: "Lato-Black",
    textTransform: "capitalize",
    logo: "#FBB31B",
    webkitBackgroundClip: "text",
    msBackgroundClip: "text",
    oBackgroundClip: "text",
    webkitTextfillColor: "transparent",
    msTextFillColor: "transparent",
    oTextfillcolor: "transparent",
  },
  button: {
    borderRadius: "7.5px",
    borderColor: "rgb(251, 179, 27)",
    color: "rgb(251, 179, 27)",
    "&hover": {
      background: "rgb(251, 179, 27)",
      color: "#ffffff",
    },
  },
}));

export default useStyles;
