import React from "react";
// @material-ui/core components

// @material-ui/icons
import UpdateIcon from '@material-ui/icons/Update';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SubjectIcon from '@material-ui/icons/Subject';

// core components
import { Grid } from "@material-ui/core";
import InfoArea from "./InfoArea";
// Buttons
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import styles from "./css/Feature1Css";

export default function ProductSection() {
    const classes = styles();
    return (
        <div className={classes.section}>
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Voice/Speech to Text</h2>
                    <h5 className={classes.description}>
                        With a support for more than 30 languages, Make you voice heard and written into text in real time using our cutting edge AI techniques. 
                        If you need to dictate any content , you will find Scriver more affordable and reliable. We assure that we provide an accuracy upto
                        95 percent for better pronounced voices. Students, professors, teachers, writers, bloggers, reporters or anyone who want their voice to be heard
                         and written into text can use this.  Make your life and time valuable by using Scriver.

                               
          </h5>
                </Grid>
            </Grid>
            <div>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Instant"
                            description="Watch text live while you dictate any content"
                            icon={UpdateIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Transcribe/Automate"
                            description="Scriver transcribes your speech to text in real time using cutting edge Artificial intelligence  techniques."
                            icon={AutorenewIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Organize"
                            description="Scriver provides  an easy  way to organize and search across your notes and other important highlights."
                            icon={SubjectIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                </Grid>

                <Grid align="center">
                    <Button
                        variant="contained"
                        color="textprimary"
                        className={classes.button}
                        href="/#/resources"
                        startIcon={<DescriptionIcon />}
                    >
                        User Guide
          </Button>
                </Grid>
                {/* </Grid> */}
            </div>
        </div>
    );
}
