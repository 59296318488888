import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    // height: "100vh",
    height: "100%",
    width: "100%",
  },
}));

export default useStyles;
