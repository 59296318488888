import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField, Button } from "@material-ui/core";

function DialogBox(props) {
  return (
    <div>
      <Dialog
        open={props.dialogTypeOpen.length >= 1}
        onClose={
          props.dialogTypeOpen === "Create New Folder" || "Create New File"
            ? props.NewFolderorFileButton
            : props.dialogTypeOpen === "Edit Folder Name" || "Edit File Name"
            ? props.EditFolderorFileButton
            : props.DeleteFolderorFileButton
        }
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.dialogTypeOpen}</DialogTitle>
        <form onSubmit={props.submitHandler}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="Name"
              label={props.dialogTypeOpen}
              type="string"
              defaultValue={
                props.dialogTypeOpen === "Delete File Name"
                  ? props.selectedFile.fileName
                  : props.dialogTypeOpen === "Edit File Name"
                  ? props.selectedFile.fileName
                  : props.dialogTypeOpen === "Delete Folder Name"
                  ? props.selectedFolder.folderName
                  : props.dialogTypeOpen === "Edit Folder Name"
                  ? props.selectedFolder.folderName
                  : ""
              }
              onChange={props.handleChange}
              onKeyPress={props.keyPressed}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={
                props.dialogTypeOpen === "Create New Folder" ||
                "Create New File"
                  ? props.NewFolderorFileButton
                  : props.dialogTypeOpen === "Edit Folder Name" ||
                    "Edit File Name"
                  ? props.EditFolderorFileButton
                  : props.DeleteFolderorFileButton
              }
              color="primary"
            >
              CANCEL
            </Button>
            <Button onClick={props.CreateEditFolderFile} color="primary">
              {props.dialogTypeOpen === "Create New Folder"
                ? "CREATE"
                : props.dialogTypeOpen === "Edit Folder Name"
                ? "EDIT"
                : props.dialogTypeOpen === "Create New File"
                ? "CREATE"
                : props.dialogTypeOpen === "Edit File Name"
                ? "EDIT"
                : "DELETE"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default DialogBox;
