import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingLeft: "25%",
    paddingRight: "25%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: "200px",
    paddingLeft: "32px",
    backgroundColor: "rgb(243, 247, 248)",
    width: "550px",
    borderRadius: "10px",
    position: "relative",
  },
  img: {
    height: 56,
    display: "block",
    maxWidth: 56,
    overflow: "hidden",
    width: "100%",
  },
  stepper: {
    background: "#ffffff",
    color: "rgb(89, 200, 226)",
  },
}));

export default useStyles;
