import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "2px 4px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    width: 300,
    height: 32,
    borderRadius: 0,
    backgroundColor: "#ffffff",
  },
  input: {
    marginLeft: "8px",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  button: {
    margin: "8px",
    borderRadius: "10px",
    fontSize: "12px",
    textTransform: "Capitalize",
    height: "32px",
    backgroundColor: "#372857",
    color: "#fafafa",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#372857",
    },
  },
  uploadButton: {
    margin: "8px",
    borderRadius: "10px",
    fontSize: "12px",
    textTransform: "Capitalize",
    height: "32px",
  },
  iframe: {
    border: "solid 2px #372857",
    transition: "border-width 0.6s linear",
    marginTop: "40px",
    "&:hover": {
      borderWidth: "3px",
    },
  },
  loginButton: {
    margin: "8px",
    borderRadius: "10px",
    fontSize: "12px",
    textTransform: "Capitalize",
    height: "32px",
  },
  login: {
    position: "relative",
  },
  loginPopupButton: {
    marginTop: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

export default useStyles;
