import React from "react";
// @material-ui/core components

// @material-ui/icons
import UpdateIcon from '@material-ui/icons/Update';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SubjectIcon from '@material-ui/icons/Subject';
// core components
import { Grid, Hidden, Divider } from "@material-ui/core";
import InfoArea from "./InfoArea";

import styles from "./css/Feature1Css";
// Buttons
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";

export default function ProductSection() {
  const classes = styles();
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={8}>
          <Hidden mdUp>
            <h3 className={classes.title1}>Features</h3>
            < Divider/>
        </Hidden>
        
                  <h2 className={classes.title}>Live Notes</h2>
          <h5 className={classes.description}>
            With the current support of 4 languages, Scriver automates an incredibly time-consuming process of capturing all of your lectures/meetings or any live conversations.
            We are here to make meetings and other voice communication far more valuable to individuals/organizations.
            Make your life and time valuable by using Scriver.
                    
          </h5>
        </Grid>
      </Grid>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea 
              title="Instant"
              description="You can now review an hour-long lecture/call in a very few minutes "
              icon={UpdateIcon}
                   
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Transcribe/Automate"
              description="Scriver comes with a google chrome extension using which you can instantly transcribe lectures, meetings, or any live video with just a single click."
              icon={AutorenewIcon}
              iconColor="primary"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
                          title="Organize"
              description="Scriver provides  an easy  way to organize and search across your notes and other important highlights."
                          icon={SubjectIcon}
              iconColor="primary"
              vertical
            />
          </Grid>
        </Grid>
              
        <Grid align="center" >
          <Button
                      variant="contained"
            color="textprimary"
            
            className={classes.button}
            href="/#/resources"
            startIcon={<DescriptionIcon />}
          >
            User Guide
          </Button>
        </Grid>
        {/* </Grid> */}
      </div>
    </div>
  );
}
