import { title } from "./../../assets/material-kit-react";
import { makeStyles } from "@material-ui/styles";
import { Space } from "antd";
import createSpacing from "@material-ui/core/styles/createSpacing";

const useStyles = makeStyles(() => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
    fontFamily:'Roboto',
  },
 
  title: {
    ...title,
    marginBottom: "1rem",
    color:"#372857",
    marginTop: "50px",
    fontSize:"40px",
    fontWeight:"400",
    fontFamily:'Roboto',
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#999",
    textAlign:"center",
    fontSize:"15px",
    fontWeight:"300",
    fontFamily:'Roboto',
    marginBottom: "1rem"
    
  },
  title1: {
    ...title,
    marginTop: "50px",
    fontSize:"50px",
    fontWeight:"350",
    fontFamily:'Roboto' ,
    minHeight: "32px",
    textDecoration: "none",
  },
  button: {
    align: "center",
    marginTop: "50px",
    },
    iconColor: {
        primary: "#372857"
    }
}));

export default useStyles;
