import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
  },
  heading: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  answer: {
    fontSize: "15px",
    fontWeight: "300px",
    fontFamily: "Roboto",
  },
  faq: {
    paddingLeft: "20%",
    paddingRight: "20%",
    marginTop: "5%",
    fontFamily: "Roboto",
  },
  input: {
    width: "350px",
    height: "40px",
    borderRadius: "10px",
    fontFamily: "Roboto",
  },
  inputPlaceholder: {
    paddingLeft: "10%",
    paddingTop: "7px",
    paddingBottom: "7px",
    fontSize: "0.8rem",
    fontFamily: "Roboto",
  },
  messageInput: {
    width: "350px",
    minHeight: "150px",
    borderRadius: "10px",
    fontFamily: "Roboto",
  },
  button: {
    borderRadius: "7.5px",
    marginBottom: "30px",
    marginTop: "20px",
    marginLeft: "10%",
    marginRight: "20%",
    borderColor: "rgb(251, 179, 27)",
    color: "black",
    textTransform: "capitalize",
    fontFamily: "Roboto",
    "&hover": {
      background: "rgb(251, 179, 27)",
      color: "#ffffff",
    },
  },
}));

export default useStyles;
