import React from "react";
import { Grid, Typography, Card, Button } from "@material-ui/core";
import FeatureBox from "./../components/FeatureBox";
import F1 from "./../images/f1.png";
import F2 from "./../images/f2.png";
import F3 from "./../images/f3.png";
import { Link } from "react-router-dom";
import { withStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        textAlign:'center',
        fontSize: 16,
        padding: '6px 12px',
        fontFamily: [
            
            'Roboto',
            
        ].join(','),
        
        '&:hover': {
            backgroundColor: '#ffffff',
            borderColor: '#ffffff',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'ffffff',
            borderColor: '#ffffff',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(255,255,255,.5)',
        },
    },
})(Button);


export default function FeatureComponent() {
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        style={{ marginTop: "50px" }}
      >
        <Grid item style={{ maxWidth: "350px" }}>
          <Grid
            container
            direction="column"
            justify="space-evenly"
            alignItems="center"
            style={{ paddingTop: "75px" }}
          >
            <Typography
              style={{
                color: "rgb(51, 51, 51)",
                fontFamily: "Roboto",
                fontSize: "40px",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              Explore The Services We Offer For You
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                textAlign:"center",
                fontFamily: "Roboto",
                color: "#787878",
                marginBottom: "30px",
              }}
            >
              Transform how you make notes by capturing, organizing, and making
              your conversations easily accessible.
            </Typography>
            <Link to="/product" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="medium"
                style={{
                  width: "150px",
                  textTransform: "capitalize",
                  background:
                    "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                }}
              >
                All Features
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Hidden mdDown>
        <Grid
          item
          style={{ width: "600px", height: "500px", position: "relative" }}
        >
          <svg
            id="bg-services"
            width="100%"
            viewBox="0 0 1000 800"
            style={{ poition: "relative" }}
          >
            <defs>
              <linearGradient
                id="PSgrad_02"
                x1="64.279%"
                x2="0%"
                y1="76.604%"
                y2="0%"
              >
                <stop
                  offset="0%"
                  stop-color="rgb(1,230,248)"
                  stop-opacity="1"
                />
                <stop
                  offset="100%"
                  stop-color="rgb(29,62,222)"
                  stop-opacity="1"
                />
              </linearGradient>
            </defs>
            <path
              fill-rule="evenodd"
              //   opacity="0.102"
              //   fill="url(#PSgrad_02)"
              fill="rgb(243, 247, 248)"
              d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"
            />
          </svg>
          <Card
            style={{
              position: "absolute",
             top: 130,
              width: "220px",
              height: "280px",
              left: 25,
              
            }}
                  >
                      <BootstrapButton href="/#/product">
                         
            <FeatureBox
              heading="Live Notes"
              description="Transcribe live lectures, meetings, or any live audio/video with just a single click instantly."
                          image={F1} />
                      
                      </BootstrapButton>
          </Card>
          <Card
            style={{
              position: "absolute",
              top: -20,
              width: "220px",
              height: "260px",
              left: 300,
            }}
                  >
                      <BootstrapButton href="/#/product">
            <FeatureBox
              heading="Voice/Speech to text"
              description="Make your voice heard and written into text in real time using our  cutting edge AI techniques"
              image={F2}
                      />
                      
                      </BootstrapButton>
          </Card>
          <Card
            style={{
              position: "absolute",
              top: 270,
              width: "220px",
              height: "260px",
              left: 300,
            }}
                  >
                      <BootstrapButton href="/#/product">
            <FeatureBox
              heading="Audio/Video to text"
              description="Accurate and easy transcription of audio/video files in few minutes or seconds"
              image={F3}
                      />
                      </BootstrapButton>
          </Card>
        </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
