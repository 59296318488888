import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
  },
  home: {
    height: "inherit",
  },
  leftDrawer: {
    backgroundColor: "#372857",
    paddingRight: "5px",
    paddingLeft: "5px",
    width: "270px",
    height: "100%",
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    overflowX: "hidden",
  },
  minLeftDrawer: {
    backgroundColor: "#372857",
    paddingRight: "5px",
    paddingLeft: "5px",
    width: "40px",
    height: "100%",
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    overflowX: "hidden",
  },
}));

export default useStyles;
