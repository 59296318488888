import { title } from "./../../assets/material-kit-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
  },

  title: {
    ...title,
    textAlign:"center",
    marginBottom: "1rem",
    fontFamily:'BlinkMacSystemFont' ,
    fontWeight:"350",
    marginTop: "120px",
    minHeight: "32px",
    fontSize: "50px",
    textDecoration: "none",
  },
  
  description: {
    color: "black",
    fontSize: "20px",
    fontWeight: 200,
  },
  sideheadings: {
    color: "black",
    fontSize: "20px",
    fontWeight: 700,
    },
    sideheadings1: {
      textDecoration: "none",
      fontSize: "30px",
      fontWeight: 700,
      },
    descriptionbold: {
        color: "black",
        fontSize: "20px",
        fontWeight: 500,
      },
    
}));

export default useStyles;
