import React from "react";
// @material-ui/core components

// @material-ui/icons
import UpdateIcon from '@material-ui/icons/Update';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SubjectIcon from '@material-ui/icons/Subject';
// core components
import { Grid } from "@material-ui/core";
import InfoArea from "./InfoArea";
// Buttons
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import styles from "./css/Feature1Css";

export default function ProductSection() {
    const classes = styles();
    return (
        <div className={classes.section}>
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Audio/Video to Text</h2>
                    <h5 className={classes.description}>
                       Scriver is created to save your valuable time to transcribe your audio/video files  to text using cutting edge AI techniques.
 If you are looking for  accurate and easy transcription of audio/video files in few minutes or seconds,this is it. Sign up here  today and start transcribing for free .
 
                                
          </h5>
                </Grid>
            </Grid>
            <div>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Instant"
                            description="Accurate and easy transcription of audio/video files in few minutes or seconds"
                            icon={UpdateIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Transcribe/Automate"
                            description="Transcribe easily by uploading your audio/video file, Tell us the language spoken, and we will convert the file into text."
                            icon={AutorenewIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Organize"
                            description="Scriver provides  an easy  way to organize and search across your notes and other important highlights."
                            icon={SubjectIcon}
                            iconColor="primary"
                            vertical
                        />
                    </Grid>
                </Grid>

                <Grid align="center">
                    <Button
                        variant="contained"
                        color="textprimary"
                        className={classes.button}
                        href="/#/resources"
                        startIcon={<DescriptionIcon />}
                    >
                        User Guide
          </Button>
                </Grid>
                {/* </Grid> */}
            </div>
        </div>
    );
}

