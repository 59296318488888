import React from "react";
import { Grid, Typography } from "@material-ui/core";
import About from "./../images/about.PNG";
import Hidden from '@material-ui/core/Hidden';
export default function AboutComponent() {
  return (
    <div style={{ paddingTop: "50px", paddingBottom: "30px" }}>
      <Typography
        style={{
          color: "rgb(51, 51, 51)",
          fontFamily: "Lato-medium",
          fontSize: "36px",
          textAlign: "center",
        }}
      >
        About
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item xs={10} md={10} style={{ maxWidth: "500px" }}>
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              textAlign:"justify",
              color: "#787878",
            }}
          >
            Tired of taking notes in lectures and meetings ?<br></br><br></br>
            At a time when most classes, meetings and events are going virtual,
             remote workers and students increasingly struggle to listen and pay attention
              while taking notes. Now more than ever, businesses and schools need tools that
               can capture information accurately and make accessible immediately. With Scriver, 
               you can now have the peace of mind to focus on the conversation knowing that
                Scriver is taking care of making the notes for you.  (It's free)
          </Typography>
          
        </Grid>
        <Hidden mdDown>
        <Grid item>
          <img
            src={About}
            alt="about"
            width="500px"
            height="275px"
            style={{ borderRadius: "10px" }}
          ></img>
        </Grid>
        </Hidden>
        <Hidden mdUp>
        <Grid item>
          <img
            src={About}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
        </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
