import React, { useState } from "react";
import { Typography, Grid, Button, InputBase, Paper, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import FAQCss from "./css/FAQCss";
import Facebook from "./../images/facebook.png";
import Twitter from "./../images/twitter.png";
import Youtube from "./../images/youtube.png";
import LinkedIn from "./../images/linkedin.png";
import Pdf from "../images/PrivacyPolicy.pdf";
import { withStyles} from '@material-ui/core/styles';
const BootstrapButton = withStyles({
  root: {
      boxShadow: 'none',
      textTransform: 'none',
      
      
      fontFamily: [
          '-apple-system',
      ].join(','),     
  },
})(Button);
export default function ContactUs() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  function handleNameChange(event) {
    setName({ value: event.target.value });
  }

  function handleEmailChange(event) {
    setEmail({ value: event.target.value });
  }

  function handleMessageChange(event) {
    setMessage({ value: event.target.value });
  }

  function handleSubmit() {
    var formdata = new FormData();
    formdata.append("emailId", email.value);
    formdata.append("body", name.value);
    formdata.append("message", message.value);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      "https://scriver.in/transcribe_app/contactForm/",
      requestOptions
    ).then((response) => response.text());
  }

  const classes = FAQCss();
  return (
    <div>
      
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        style={{ marginTop: "70px" }}
      >
        <Grid item>
        
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Typography
              style={{
                marginBottom: "20px",
                color: "black",
              }}
              variant="h5"
              gutterBottom
            >
              Contact Us
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="flex-start"
            >
              <Grid item>
                <Paper elevation={3} component="form" className={classes.input}>
                  <InputBase
                    className={classes.inputPlaceholder}
                    placeholder="Name"
                    onChange={handleNameChange}
                    value={name.value}
                  />
                </Paper>
                <Paper
                  component="form"
                  style={{ marginTop: "20px" }}
                  elevation={3}
                  className={classes.input}
                >
                  <InputBase
                    className={classes.inputPlaceholder}
                    placeholder="Email"
                    onChange={handleEmailChange}
                    value={email.value}
                  />
                </Paper>
                <Paper
                  component="form"
                  style={{ marginTop: "20px" }}
                  elevation={3}
                  className={classes.messageInput}
                >
                  <InputBase
                    className={classes.inputPlaceholder}
                    placeholder="Message"
                    onChange={handleMessageChange}
                    value={message.value}
                    multiline
                    rows={4}
                  />
                </Paper>
                <Button
                  variant="outlined"
                  size="medium"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
        <Grid item>
        
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item>
            <Hidden xsDown>
              <Grid
              
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              > 
                              <Grid item
                                  style={{
                                      marginRight: "200px"
                                  }}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography
                      style={{
                        marginBottom: "20px",
                        color: "black",
                      }}
                      variant="h5"
                      gutterBottom
                    >
                      Scriver
                    </Typography>
                    <Link
                      style={{
                        textDecoration: "none",
                        fontFamily: "OpenSans-Regular",
                        fontSize: "20px",
                        color: "#666666",
                      }}
                      to="/"
                    >
                      Home
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                        fontFamily: "OpenSans-Regular",
                        fontSize: "20px",
                        color: "#666666",
                      }}
                      to="/product"
                    >
                      Features
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                        fontFamily: "OpenSans-Regular",
                        fontSize: "20px",
                        color: "#666666",
                      }}
                      to="/contact"
                    >
                      FAQ's
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                        fontFamily: "OpenSans-Regular",
                        fontSize: "20px",
                        color: "#666666",
                      }}
                      to="/resources"
                    >
                      User Guides
                    </Link>
                


                                      <a href="/#/privacy" target="_blank" style={{
                                          textDecoration: "none",
                                          fontFamily: "OpenSans-Regular",
                                          fontSize: "20px",
                                          color: "#666666",
                                      }}>Privacy Policy</a>
                                      
  
                  </Grid>
                  
                </Grid>
                
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    
                  >
                    
                    <Grid item>
                      <Typography
                        style={{
                          marginBottom: "20px",
                          color: "black",
                        }}
                        variant="h5"
                        gutterBottom
                      >
                        Follow Us
                      </Typography>
                      
                    </Grid>
                    
                    <Grid 
                    container
                    
                    alignItems="center">
                      <BootstrapButton href= "https://www.facebook.com/scriver.in" target="_blank" >
                      <img
                        src={Facebook}
                        width="24px"
                        height="24px"
                        style={{ marginRight: "15px" }}
                        alt="facebook"
                      ></img></BootstrapButton>
                      <img
                        src={Twitter}
                        width="24px"
                        height="24px"
                        style={{ marginRight: "15px" }}
                        alt="twitter"
                      ></img>
                      <img
                        src={LinkedIn}
                        width="24px"
                        height="24px"
                        style={{ marginRight: "15px" }}
                        alt="linkedIn"
                      ></img>
                      <img
                        src={Youtube}
                        width="24px"
                        height="24px"
                        style={{ marginRight: "15px" }}
                        alt="youtube"
                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </Hidden>
            </Grid>
            
            <Grid item style={{ paddingTop: "50px" }}>
              <Typography
                style={{
                  color: "#999999",
                  fontFamily: "Lato-Regular",
                  fontSize: "18px",
                }}
              >
                © 2020. All Rights Reserved
              </Typography>
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
      
    </div>
  );
}
