import React, { useEffect } from "react";
import { Grid, Typography, Button, Hidden } from "@material-ui/core";
import LandingPageCss from "./css/LandingPageCss";
import HeaderLanding from "./../components/HeaderLanding";
import FeatureComponent from "./../components/FeatureComponent";
import StatisticsComponent from "./../components/StatisticsComponent";
import ContactUs from "./../components/ContactUs";
import ReviewBox from "./../components/ReviewBox";
import AboutComponent from "./../components/AboutComponent";
import Background from "./../images/6.svg";
import logo from "./../images/logo1W.png";
import { useHistory } from "react-router-dom";

function LandingPage(props) {
  const classes = LandingPageCss();

  let history = useHistory();
  useEffect(() => {
    props.email !== "" && history.push("/");
  }, [history, props.email]);

  return (
    <div className={classes.root}>
      <HeaderLanding
        name={props.name}
        email={props.email}
        image={props.image}
        logout={props.logout}
        responseGoogle={props.responseGoogle}
        setName={props.setName}
        setEmail={props.setEmail}
      />
      
      <div
        style={{
          maxHeight: "92vh",
          backgroundImage: `url(${Background})`,
          marginTop: "48px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'left',
        }}
      >
      
        

        <div style={{ maxWidth: "600px" }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              paddingTop: "25px",
              minHeight: "92vh",
              maxWidth: "500px",
              margin: "left",
            }}
          >
            <Grid  >
              <img
                src={logo}
                alt="logo"
                style={{ width: 300, height: 90 }}
              ></img>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontFamily: "ProximaNovaLight",
                  fontWeight: 300,
                  fontSize: "16px",
                  color: "#59C8E2",
                  textAlign:"center",
                }}
                gutterBottom
              >
                We write for you with our AI-powered assistant. (it’s free)
              </Typography>
            </Grid>
            <Grid item xs={10} md={10}style={{ marginTop: "5px" }}>
              <Typography
                style={{
                  fontFamily: "ProximaNovaLight",
                  fontSize: "16px",
                  lineHeight: "1.8em",
                  color: "#ffffff",
                  textAlign: "center",
                  
                }}
                gutterBottom
              >
                Make high quality notes for your lectures,meetings, interviews,
                and other important voice conversations with our AI powered
                assistant.
              </Typography>
            </Grid>
            <Hidden xsDown>
            <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  width: "250px",
                  textTransform: "capitalize",
                  background:
                    "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                }}
                href="https://chrome.google.com/webstore/detail/scriver/gnpnkegblakpcagljbhbecdpokknlemo" target="_blank"
              >
                Get Chrome Extension
              </Button>
            </Grid>
            </Hidden>
            <Hidden smUp>
            <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  width: "350px",
                  textTransform: "capitalize",
                  background:
                    "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                }}
              >
                Currently Available only on Desktop
              </Button>
            </Grid>
            </Hidden>
          </Grid>
          </div>
          </div> 
          
      
      
     
      <AboutComponent />
      <FeatureComponent />
      <StatisticsComponent />   
      <ContactUs />
      
    </div>
  );
}

export default LandingPage;
