import React from "react";
import Header from "../components/Header";
import CenterPageCss from "./css/CenterPageCss";
import WelcomeMessage from "../components/WelcomeMessage";

export default function CenterPage(props) {
  const classes = CenterPageCss();
  return (
    <div style={{ height: "100%" }}>
      <Header
        isLoggedIn={props.isLoggedIn}
        docs={props.docs}
        responseGoogle={props.responseGoogle}
        name={props.name}
        email={props.email}
        url={props.url}
        URL={props.URL}
        setURL={props.setURL}
        activeFile={props.activeFile}
        logout={props.logout}
        accessToken={props.accessToken}
        fileURL={props.fileURL}
        folders={props.folders}
        minimized={props.minimized}
        URLSetter={props.URLSetter}
        setSelectedFolder={props.setSelectedFolder}
        setSelectedFile={props.setSelectedFile}
        selectedFolder={props.selectedFolder}
        selectedFile={props.selectedFile}
        style={{ height: "63px", backgroundColor: "rgb(235, 243, 248)" }}
      />
      {props.URL === "" || undefined ? (
        <div
          style={{
            marginTop: "-37px",
            marginLeft: "80px",
            marginBottom: "-37px",
            width: "85%",
            minHeight: "100vh",
            marginRight: "10px",
          }}
        >
          <WelcomeMessage />
        </div>
      ) : (
        <div
          style={{
            marginTop: "63px",
            marginLeft: "80px",
            height: "1350px",
            width: "85%",
            marginRight: "10px",
          }}
        >
          <iframe
            title="docFrame"
            src={props.URL}
            width="100%"
            height="1300px"
            className={classes.iframe}
          ></iframe>
        </div>
      )}
    </div>
  );
}
