import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    color: "rgb(255,255,255)",
    paddingTop: "12px",
    paddingLeft: "10px",
    fontFamily: "Lucida Console",
  },
  menuButton: {
    paddingTop: "20px",
  },
  menu: {
    color: "rgb(255,255,255)",
  },
  folderTree: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
    color: "rgb(255,255,255)",
  },
  folderDirectory: {
    marginTop: "20px",
  },
  rootFolderName: {
    fontFamily: "system-ui",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    margin: "auto",
    color: "#ffffff",
  },
  folder: {
    paddingLeft: "8px",
    paddingRight: "8px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  button: {
    color: "#ffffff",
    "&:hover": {
      color: "rgb(255,255,255)",
    },
  },
  foldersList: {
    paddingTop: "2px",
    paddingBottom: "2px",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    fontFamily: "system-ui",
    fontWeight: 400,
    margin: "auto",
    "&:hover": {
      opacity: 0.8,
    },
  },
  selectedText: {
    color: "#FBB31B",
  },
  filesList: {
    paddingLeft: "45px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "8px",
  },
  foldersButton: {
    opacity: 0,
    "&:hover": {
      opacity: 1,
      color: "rgb(255,255,255)",
    },
  },
  Dialog: {
    width: "500px",
    height: "500px",
  },
  fontStyle: {
    color: "#fafafa",
  },
  deleteButton: {
    borderRadius: "7.5px",
    marginBottom: "30px",
    marginTop: "30px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "27%",
    paddingRight: "27%",
    borderColor: "rgb(251,179,27)",
    color: "white",
    "&hover": {
      background: "#fff",
      color: "#ffffff",
    },
  },
}));

export default useStyles;
