import React from "react";
import { Button } from "@material-ui/core";
import CenterPageCss from "../pages/css/CenterPageCss";
import AudioVideoDialog from "./AudioVideoDialog";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import SpeechRecognition from "react-speech-recognition";

const RecordAudioButton = ({
  buttonState,
  setDialogOpen,
  setHeading,
  setButtonState,
  dialogOpen,
  folders,
  docs,
  heading,
  startListening,
  stopListening,
  handleRecordUpload,
  handleRecordClick,
  folder,
  file,
  language,
  setFolder,
  setLanguage,
  setFile,
}) => {
  const classes = CenterPageCss();
  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        className={classes.button}
        startIcon={buttonState ? <StopIcon /> : <PlayArrowIcon />}
        onClick={handleRecordClick}
      >
        {buttonState ? "Stop" : "Record"}
      </Button>
      <AudioVideoDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        folders={folders}
        docs={docs}
        heading={heading}
        buttonState={buttonState}
        setButtonState={setButtonState}
        handleRecordUpload={handleRecordUpload}
        folder={folder}
        file={file}
        language={language}
        setFolder={setFolder}
        setFile={setFile}
        setLanguage={setLanguage}
      />
    </div>
  );
};

const options = {
  autoStart: false,
};

export default SpeechRecognition(options)(RecordAudioButton);
