import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FolderIcon from "@material-ui/icons/Folder";
import { ListItem, Button, Grid, Typography } from "@material-ui/core";
import LeftDrawerCss from "./css/LeftDrawerCss";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DialogBox from "./DialogBox";

export default function Folder(props) {
  const classes = LeftDrawerCss();
  return (
    <div>
      {props.folders.map((folder, i) => (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item style={{ height: "36px" }}>
            <Button
              style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px" }}
              onClick={() => props.setFolder(folder)}
            >
              <ListItem
                style={{ display: "flex", flexDirection: "row" }}
                className={classes.foldersList}
              >
                <ListItemIcon style={{ minWidth: "30px", flex: 1 }}>
                  <FolderIcon
                    style={
                      props.selectedFolder.folderName === folder.folderName
                        ? { color: "#FBB31B" }
                        : { color: "#fafafa" }
                    }
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    fontSize: "0.9rem",
                    fontFamily: "system-ui",
                    flex: 6,
                  }}
                  className={
                    props.selectedFolder.folderName === folder.folderName
                      ? classes.selectedText
                      : classes.fontStyle
                  }
                >
                  {folder.folderName}
                </Typography>
                {props.selectedFolder.folderId === folder.folderId ? (
                  <ExpandLess
                    fontSize="medium"
                    style={
                      props.selectedFolder.folderId === folder.folderId
                        ? { color: "#FBB31B" }
                        : { color: "#fafafa" }
                    }
                  />
                ) : (
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      props.selectedFolder.folderId === folder.folderId
                        ? { color: "#FBB31B" }
                        : { color: "#fafafa" }
                    }
                  />
                )}
              </ListItem>
            </Button>
          </Grid>
          {props.selectedFolder.folderId === folder.folderId &&
            props.docs.map((doc, i) => {
              if (doc.folderId === folder.folderId) {
                return (
                  <Grid item style={{ height: "30px" }}>
                    <Button
                      style={{ height: "30px", width: "100%" }}
                      onClick={() => props.setFile(doc)}
                    >
                      <ListItem style={{ paddingLeft: "45px" }}>
                        <ListItemIcon
                          style={{
                            minWidth: "30px",
                            marginTop: "-8px",
                          }}
                          className={
                            doc.fileId === props.selectedFile.fileId
                              ? classes.selectedText
                              : classes.fontStyle
                          }
                        >
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            marginTop: "-8px",
                            fontFamily: "system-ui",
                            textTransform: "Capitalize",
                          }}
                          className={
                            doc.fileName === props.selectedFile.fileName
                              ? classes.selectedText
                              : classes.fontStyle
                          }
                        >
                          {doc.fileName}
                        </Typography>
                      </ListItem>
                    </Button>
                  </Grid>
                );
              }
              return null;
            })}
        </Grid>
      ))}
      <DialogBox
        NewFolderorFileButton={props.NewFolderorFileButton}
        EditFolderorFileButton={props.EditFolderorFileButton}
        dialogTypeOpen={props.dialogTypeOpen}
        submitHandler={props.submitHandler}
        handleChange={props.handleChange}
        keyPressed={props.keyPressed}
        DeleteFolderorFileButton={props.DeleteFolderorFileButton}
        CreateEditFolderFile={props.CreateEditFolderFile}
        selectedFolder={props.selectedFolder}
        selectedFile={props.selectedFile}
      />
    </div>
  );
}
