import React, { useState } from "react";
import { Grid, Divider, Typography, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LeftDrawerCss from "./css/LeftDrawerCss";
import { Button } from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Folder from "./Folder";
import Popover from "@material-ui/core/Popover";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogBox from "./DialogBox";
import logo from "./../images/logo1Y.png";

export default function LeftDrawer(props) {
  const classes = LeftDrawerCss();
  const [dialogTypeOpen, setDialogTypeOpen] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentField, setCurrentField] = useState();
  const [iconState, setIconState] = useState("");

  function setFolder(field) {
    if (props.selectedFolder.folderId === field.folderId) {
      props.setSelectedFolder({});
      props.setSelectedFile({});
    } else {
      props.setSelectedFolder({
        folderId: field.folderId,
        folderName: field.folderName,
      });
      props.setSelectedFile({});
    }
  }

  function setFile(field) {
    props.setURL("https://docs.google.com/document/d/" + field.fileId + "/");
    props.setFileURL(
      "https://docs.googleapis.com/v1/documents/" + field.fileId
    );
    props.setSelectedFile({
      fileId: field.fileId,
      fileName: field.fileName,
      folderId: field.folderId,
    });

    if (props.selectedFile.fileId === field.fileId) {
      props.setURL("");
      props.setFileURL("");
      props.setSelectedFile({});
    }
  }

  var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIconState("Add folders/files to your directory");
  };

  const handlePopoverEditOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIconState("Edit folder names/file names in your directory");
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function handleChange(event) {
    setCurrentField({ value: event.target.value });
  }

  function NewFolderorFileButton() {
    if (
      props.selectedFolder.folderId === undefined &&
      dialogTypeOpen.length === 0
    )
      setDialogTypeOpen("Create New Folder");
    else if (
      props.selectedFolder.folderId === undefined &&
      dialogTypeOpen.length >= 1
    ) {
      setDialogTypeOpen("");
    } else if (
      props.selectedFolder.folderId !== undefined &&
      dialogTypeOpen.length === 0
    )
      setDialogTypeOpen("Create New File");
    else if (
      props.selectedFolder.folderId !== undefined &&
      dialogTypeOpen.length >= 1
    ) {
      setDialogTypeOpen("");
    }
  }

  function EditFolderorFileButton() {
    if (
      props.selectedFolder.folderName !== undefined &&
      dialogTypeOpen.length === 0 &&
      props.selectedFile.fileName !== undefined
    ) {
      setDialogTypeOpen("Edit File Name");
    } else if (
      props.selectedFolder.folderName !== undefined &&
      dialogTypeOpen.length === 0 &&
      props.selectedFile.fileName === undefined
    ) {
      setDialogTypeOpen("Edit Folder Name");
    } else if (dialogTypeOpen.length >= 1) {
      setDialogTypeOpen("");
    } else {
      setDialogTypeOpen("");
    }
  }

  function DeleteFolderorFileButton() {
    if (
      props.selectedFolder.folderName === undefined &&
      props.selectedFile.fileName === undefined
    ) {
      setDialogTypeOpen("");
    } else if (
      props.selectedFolder.folderName !== undefined &&
      dialogTypeOpen.length === 0 &&
      props.selectedFile.fileName === undefined
    ) {
      setDialogTypeOpen("Delete Folder Name");
    } else {
      setDialogTypeOpen("Delete File Name");
    }
  }

  function CreateEditFolderFile() {
    if (dialogTypeOpen === "Create New Folder") {
      setDialogTypeOpen("");
      fetch("https://scriver.in/transcribe_app/createFolder/", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          folderName: currentField.value,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          props.setFolders([
            ...props.folders,
            { folderId: data.folderId, folderName: data.folderName },
          ]);
          props.setSelectedFolder({});
          props.setURL("");
          props.setFileURL("");
          props.setSelectedFile({});
        });
    } else if (dialogTypeOpen === "Edit Folder Name") {
      setDialogTypeOpen("");
      var folderNames = props.folders;
      var fileNames = props.docs;
      fetch("https://scriver.in/transcribe_app/updateFolder/", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          folderId: props.selectedFolder.folderId,
          folderName: currentField.value,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          folderNames.map((folder) => {
            if (folder.folderId === data.folderId)
              folder.folderName = data.folderName;
            return null;
          });
          fileNames.map((file) => {
            if (file.folderId === props.selectedFolder.folderId)
              file.folderId = currentField.value;
            return null;
          });
          props.setFolders(folderNames);
          props.setDocs(fileNames);
          props.setSelectedFolder({
            folderId: data.folderId,
            folderName: data.folderName,
          });
          props.setURL("");
          props.setFileURL("");
          props.setSelectedFile({});
        });
    } else if (dialogTypeOpen === "Create New File") {
      setDialogTypeOpen("");
      var bearer = "Bearer " + props.accessToken;
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", bearer);
      var raw = JSON.stringify({ title: currentField.value });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        "https://docs.googleapis.com/v1/documents?key=AIzaSyDOsAjz_T52b5phX-R17SpkNdM5-kZ3FaQ",
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          fetch("https://scriver.in/transcribe_app/addFile/", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
              folderId: props.selectedFolder.folderId,
              fileName: currentField.value,
              fileId: data.documentId,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              props.setDocs(() => [
                ...props.docs,
                {
                  fileId: data.fileId,
                  fileName: data.fileName,
                  folderId: data.folderId,
                },
              ]);
            });
        });
    } else if (dialogTypeOpen === "Edit File Name") {
      setDialogTypeOpen("");
      bearer = "Bearer " + props.accessToken;
      myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", bearer);
      var url =
        "https://www.googleapis.com/drive/v2/files/" +
        props.selectedFile.fileId;
      raw = JSON.stringify({
        title: currentField.value,
      });
      requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          fetch("https://scriver.in/transcribe_app/updateFile/", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
              fileName: currentField.value,
              fileId: data.id,
              folderId: props.selectedFile.folderId,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              var fileNames = props.docs;
              fileNames.map((file) => {
                if (file.fileId === data.fileId) file.fileName = data.fileName;
                return null;
              });
              props.setDocs(fileNames);
              props.setURL(
                "https://docs.google.com/document/d/" + data.fileId + "/edit"
              );
              props.setFileURL(
                "https://docs.googleapis.com/v1/documents/" + data.fileId
              );
              props.setSelectedFile({
                fileId: data.fileId,
                fileName: data.fileName,
                folderId: data.folderId,
              });
            });
        });
    } else if (dialogTypeOpen === "Delete Folder Name") {
      setDialogTypeOpen("");
      folderNames = props.folders;
      fileNames = props.docs;
      fetch("https://scriver.in/transcribe_app/deleteFolder/", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          folderId: props.selectedFolder.folderId,
          folderName: props.selectedFolder.folderName,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          folderNames.map((folder) => {
            if (folder.folderId === data.folderId)
              folderNames = removeByAttr(
                folderNames,
                "folderId",
                data.folderId
              );
            return null;
          });
          fileNames.map((file) => {
            if (file.folderId === props.selectedFolder.folderId)
              fileNames = removeByAttr(fileNames, "folderId", data.folderId);
            return null;
          });
          props.setFolders(folderNames);
          props.setDocs(fileNames);
          props.setSelectedFolder({});
          props.setURL("");
          props.setFileURL("");
          props.setSelectedFile({});
        });
    } else if (dialogTypeOpen === "Delete File Name") {
      setDialogTypeOpen("");
      bearer = "Bearer " + props.accessToken;
      myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", bearer);
      url =
        "https://www.googleapis.com/drive/v2/files/" +
        props.selectedFile.fileId;
      requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(url, requestOptions).then((response) => {
        fetch("https://scriver.in/transcribe_app/deleteFile/", {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            fileId: props.selectedFile.fileId,
            folderId: props.selectedFile.folderId,
            fileName: props.selectedFile.fileName,
          }),
        }).then((response) => {
          var fileNames = props.docs;
          fileNames.map((file) => {
            if (file.fileId === props.selectedFile.fileId)
              fileNames = removeByAttr(
                fileNames,
                "fileId",
                props.selectedFile.fileId
              );
            return null;
          });
          props.setDocs(fileNames);
          props.setURL("");
          props.setFileURL("");
          props.setSelectedFile({});
        });
      });
    }
  }

  const keyPressed = ({ key }) => {
    // Capture search on Enter key
    if (key === "Enter") {
      CreateEditFolderFile();
    }
  };

  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };

  const open = Boolean(anchorEl);
  return (
    <div className={classes.root}>
      <Grid container style={{ flex: 1 }}>
        {!props.minimized && (
          <Grid item xs={10}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "45px" }}
              className={classes.logo}
            ></img>
          </Grid>
        )}
        <Grid item className={classes.menuButton} xs={2}>
          <IconButton size="small" onClick={props.toggleButton}>
            <MenuIcon className={classes.menu} />
          </IconButton>
        </Grid>
      </Grid>
      {!props.minimized && (
        <div style={{ flex: 10 }}>
          <Divider className={classes.folderDirectory} />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.folder}
          >
            <Grid item xs={8}>
              <Typography align="justify" className={classes.rootFolderName}>
                Folders
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton size="small" onClick={EditFolderorFileButton}>
                <EditIcon
                  onMouseEnter={handlePopoverEditOpen}
                  onMouseLeave={handlePopoverClose}
                  className={classes.button}
                />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton size="small" onClick={NewFolderorFileButton}>
                <AddBoxOutlinedIcon
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  className={classes.button}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Folder
            folders={props.folders}
            setFolders={props.setFolders}
            docs={props.docs}
            CreateEditFolderFile={CreateEditFolderFile}
            dialogTypeOpen={dialogTypeOpen}
            NewFolderorFileButton={NewFolderorFileButton}
            EditFolderorFileButton={EditFolderorFileButton}
            DeleteFolderorFileButton={DeleteFolderorFileButton}
            selectedFolder={props.selectedFolder}
            setFolder={setFolder}
            selectedFile={props.selectedFile}
            setFile={setFile}
            handleChange={handleChange}
            submitHandler={submitHandler}
            keyPressed={keyPressed}
          />
          <DialogBox
            NewFolderorFileButton={NewFolderorFileButton}
            EditFolderorFileButton={EditFolderorFileButton}
            DeleteFolderorFileButton={DeleteFolderorFileButton}
            dialogTypeOpen={dialogTypeOpen}
            submitHandler={submitHandler}
            handleChange={handleChange}
            keyPressed={keyPressed}
            CreateEditFolderFile={CreateEditFolderFile}
            selectedFolder={props.selectedFolder}
            selectedFile={props.selectedFile}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography style={{ fontSize: "10px" }}>{iconState}</Typography>
          </Popover>
        </div>
      )}
      {!props.minimized && (
        <Button
          style={{ width: "100%", height: "32px" }}
          onClick={DeleteFolderorFileButton}
          variant="outlined"
          size="large"
          className={classes.deleteButton}
        >
          <DeleteIcon style={{ fontSize: "1rem" }} />
          <Typography
            align="left"
            style={{
              paddingLeft: "10px",
              fontSize: "1rem",
              textTransform: "capitalize",
            }}
          >
            Delete
          </Typography>
        </Button>
      )}
    </div>
  );
}
