import React from "react";
import { Typography, Tabs, Tab, Box, Grid,AppBar, Hidden } from "@material-ui/core";
import UserGuidesCss from "./css/UserGuideCss";
import HeaderLanding from "../components/HeaderLanding";
import Parallax from "../components/Parallax";
// import ParallaxImage from "../images/7.svg";
import web1 from "../images/web1.png";
import web2 from "../images/web2.png";
import web3 from "../images/web3.png";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UserGuides(props) {
  const classes = UserGuidesCss();
  
  

  // const Feature1Points = [
  //   "Signup/ Login to your account.",
  //   "Download the chrome extension",
  //   "Open your desktop’s Chrome browser with Group Onn’s chrome extension installed",
  //   "Go to the extension",
  //   "To start recording your video lectures/meetings, Select the folder and file where you want to save your transcriptions",
  //   "Click the Start button. For the first time, Chrome browser will request your permission to access the microphone. Choose allow",
  //   "Notes onn will save your transcription to the file selected.",
  //   "You may start and stop any number of times.",
  // ];

  // const Feature2Points = [
  //   "Connect a high-quality microphone to your computer.",
  //   "Make sure your microphone is set as default recording device on your browser.",
  //   "Signup/ Login to your account.",
  //   "In the landing page, on the top right corner, you can click the speaker button to select the language you would like to speak.",
  //   "Select the folder and file where you want to see your text.",
  //   "Click the Start button. For the first time, Chrome browser will request your permission to access the microphone. Choose allow.",
  //   "You may speak now!",
  // ];

  // const Feature3Points = [
  //   "Signup/ Login to your account.",
  //   "In the landing page, on the top right corner, you can click the upload button to upload your audio/video file and select the language of your file.",
  //   "Select the folder and file where you want to see your text.",
  //   "Click the Start button",
  //   "You can see your text",
  // ];

  // const Feature1List = Feature1Points.map((feature) => <li>{feature}</li>);
  // const Feature2List = Feature2Points.map((feature) => <li>{feature}</li>);
  // const Feature3List = Feature3Points.map((feature) => <li>{feature}</li>);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <HeaderLanding
        name={props.name}
        email={props.email || ""}
        logout={props.logout}
        image={props.image}
        setName={props.setName}
        setEmail={props.setEmail}
      />
      <Hidden smDown>
      <Parallax>
        <div className={classes.container}>
          <Grid Container>
            <Grid Item xs={12} sm={12} md={12}>
              <h1 className={classes.title}>User Guides</h1>
              {/* <h4>we have three products***</h4> */}
              <br />
            </Grid>
           
          </Grid>
        </div>
      </Parallax>
      </Hidden>
      <Hidden smUp>
        <div className={classes.container}
        style={{
          maxHeight: "92vh",
          marginTop: "35px",
          
          }}>
          <Grid Container>
            <Grid Item xs={12} sm={12} md={12}><br />
            </Grid>
          </Grid>
        </div>
        </Hidden>
      <div className={classes.positionRelative}>
      <AppBar position="static"  color="default">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
          <Tab label="Live Notes" {...a11yProps(0)} />
          <Tab label="Speech to Text" {...a11yProps(1)} />
          <Tab label="Audio/Video to Text" {...a11yProps(2)} />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <img
            src={web1}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <img
            src={web2}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <img
            src={web3}
            alt="about"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></img>
      </TabPanel>
    
    </div>
    <Grid container
            direction="column"
            justify="space-between"
            alignItems="center"
            >
    <Grid item style={{ paddingTop: "50px"}}>
              <Typography
                style={{
                  color: "#999999",
                  fontFamily: "Lato-Regular",
                  fontSize: "18px",
                }}
              >
                © 2020. All Rights Reserved
              </Typography>
            </Grid>
            </Grid>
  </div>
   );
}
