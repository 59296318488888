import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function FeatureBox(props) {
  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "20px" }}
      >
        <Grid
          item
            style={{   
            // background: "linear-gradient(to right, #f1f6fd, #f0fbfe)",
            backgroundColor: "rgb(243, 247, 248)",
            padding: "10px",
            width: "160px",
            borderRadius: "50%",
            margin: "auto",
            transform: "rotate(20deg)",
            height: "110px",
          }}
        >
          <img
            style={{
              maxWidth: "1.2in",
              marginTop: "20px",
              marginLeft: "15px",
              transform: "rotate(-20deg)",
            }}
            src={props.image}
          ></img>
        </Grid>
        <Grid
          item
          style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            // paddingBottom: "12px",
          }}
        >
          <Typography
            style={{
              color: "rgb(89, 200, 226)",
              fontSize: "16px",
                          fontFamily: "Lato-Regular",
                          
            }}
          >
            {props.heading}
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <Typography
            style={{
              fontSize: "12px",
              color: "#666666",
                          fontFamily: "OpenSans-Regular",
                          
            }}
          >
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
