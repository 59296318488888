import React, { useState } from "react";
import { Button, TextField, InputBase } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import HeaderLanding from "./HeaderLanding";
import { withStyles } from "@material-ui/core/styles";

const TextInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "1px solid rgb(55, 40, 87)",
        fontSize: 16,
        width: "360px",
        padding: "10px 12px",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderColor: "rgb(55, 40, 87)",
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(3),
        },
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    field: {
        borderColor: "rgb(55, 40, 87)",
    },
}));
function updateName(name, email, setbuttonDisabled) {
    fetch("https://scriver.in/transcribe_app/updateProfile/", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            userName: name,
            emailId: email,
        }),
    }).then((response) => {
        return response.json();
    });
    setbuttonDisabled(true);
}
function AccountSettings(props) {
    const [buttonDisabled, setbuttonDisabled] = useState(true);
    const classes = useStyles();
    return (
        <div
            style={{ backgroundColor: "#FFFFFF", maxWidth: "100vw", height: "100%" }}
        >
            <HeaderLanding
                name={props.name}
                email={props.email || ""}
                image={props.image}
                logout={props.logout}
                setName={props.setName}
                setEmail={props.setEmail}
            />
            <Grid
                container
                direction="row"
                alignItems="center"
                style={{ paddingTop: "100px" }}
            >
                <Grid item xs={3} align="right">
                    Profile photo :
        </Grid>
                <div className={classes.root}>
                    <Avatar
                        alt="Remy Sharp"
                        src={props.image}
                        className={classes.large}
                    />
                </div>
            </Grid>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={3} align="right" style={{ marginBottom: "20px" }}>
                    Name :
        </Grid>
                <Grid item xs={9} style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={6}>
                            <TextInput
                                id="name"
                                value={props.name}
                                onChange={(e) => {
                                    props.setName(e.target.value);
                                }}
                                defaultValue={props.name}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                onClick={() => {
                                    setbuttonDisabled(!buttonDisabled);
                                }}
                                aria-label="edit"
                                className={classes.margin}
                                size="small"
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginBottom: "20px" }} align="right">
                    Email Id :
        </Grid>
                <Grid item xs={4} style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                    <TextField
                        style={{ width: "386px" }}
                        id="email"
                        disabled
                        defaultValue={props.email}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={3} align="right" style={{ marginBottom: "20px" }}>
                    Remaining hours :
        </Grid>
                <Grid item xs={2} style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                    <TextField
                        style={{ width: "386px" }}
                        id="remHours"
                        disabled
                        defaultValue="10"
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
            <div align="right">
                {!buttonDisabled && (
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={3}>
                            <Button variant="outlined" size="small" color="primary">
                                Cancel
              </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    updateName(props.name, props.email, setbuttonDisabled);
                                }}
                            >
                                Save
              </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        </div>
    );
}

export default AccountSettings;